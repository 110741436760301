import React from 'react';
import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import CardHeader from '../../../containers/CardHeader'
import Form from '../../../containers/Form/Form'

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_tj_autobid_list, tool_tj_autobid_save } from "../../../app/apiEndpoints"
import { tableTypes } from "../../../app/settings"
import DataTable from "../../../containers/DataTable";
import { FaTrash } from 'react-icons/fa'
import { Card, Row, Col, InputGroup, FormControl } from 'react-bootstrap'

const TJBidSchedulerTool = (props) => {
    const toolID = 3
    const [tool, setTool] = useState({})
    const [source, setSource] = useState(1)
    const [campaignids, setCampaignids] = useState('')
    const [formMessage, setFormMessage] = useState('')
    const [formStatus, setFormStatus] = useState(0)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [campaigns, setCampaigns] = useState([])

    const [bidsByHour, setBidsByHour] = useState([...Array(24).keys()].fill(''))

    const tableID = "campaign"
    const tableFields = ["Campaign ID", "Source", "Campaign Name", "Configurations", ""]

    console.log("bidsByHour", bidsByHour)
    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_tj_autobid_list, { 'requester': userID })
                setCampaigns(data.campaigns)
            }
        }
        getDetails()
    }, [userID])

    const loadCampaign = (e) => {
        if(formStatus === 1) return;

        let rowID = e.target.name
        rowID = rowID.replace(`edit_`, "")
        
        const campaign = campaigns.find((camp) => camp.campaign_id === rowID)
        if(!campaign) return

        setCampaignids(campaign.campaign_id)
        setSource(campaign.source)

        const bids = []
        for (let i = 0; i < 24; i++) {
            if(campaign.bidsByHour[i]) bids.push(parseFloat(campaign.bidsByHour[i].bid))
            else bids.push('')
        }
        console.log('bids', bids)
        setBidsByHour(bids)
        setFormStatus(0)
    }

    const tableData = () => {
        if(campaigns.length === 0) {
            return [];
        } 
        console.log('data', campaigns)
        let rows = [];
        campaigns.forEach(row => {
            rows.push({'id': row.campaign_id, 'editFnc': loadCampaign, 'values': [
                row.campaign_id,
                row.source === "1" ? "Banners" : "Popups",
                row.campaign_name,
                row.bids
            ]});
        });
        return rows
    }

    const updateCampaigns = async (e) => {
        e.preventDefault()
        setFormMessage("")

        if(!campaignids) {
            setFormMessage("Please enter at least one Campaign ID")
            return
        }

        // split rows and remove duplicates
        let ids = campaignids.split("\n")
        ids = ids.filter(function(item, pos) {
            return ids.indexOf(item) === pos;
        })
        
        setFormStatus(1)
        
        const data = await toolsAPICall(tool_tj_autobid_save, { 'campaignids': ids, 'source': source, 'bids': bidsByHour, 'requester': userID })
        
        if(data.error) {
            setFormMessage(data.error)
            setFormStatus(0)
        } 
        else if(data.campaigns) {
            setFormMessage("Bids have been scheduled")
            setCampaigns(data.campaigns)
            setFormStatus(2)
        }
    }

    const printHour = (hour, pm) => {
        const newHour = hour + (pm ? 12 : 0)
        if(newHour === 0) return `12:00am`
        else if(newHour < 12) return `${newHour}:00am`
        else if(newHour === 12) return `${newHour}:00pm`
        else return `${(newHour-12)}:00pm`
    }

    const setBid = (e) => {
        const { name, value } = e.target;

        const hour = name.replace(`bid_`, "")
        let newBids = [...bidsByHour];
        newBids[hour] = value;

        setBidsByHour(newBids)
    }

    const restart = (e) => {
        e.preventDefault()
        setCampaignids('')
        setBidsByHour([...Array(24).keys()].fill(''))
        setFormStatus(0)
        setFormMessage('')
    }

    const clearBids = (e) => {
        e.preventDefault()
        setBidsByHour([...Array(24).keys()].fill(''))
    }

    if(!tool) return <></>

    return (
        <>
        <Card>
            <Row>
                <Col md={6}>
                    <Form title={tool.tool_name} description={tool.description} onSubmit={updateCampaigns} noCard={true}>
                    <Card.Body>
                        <Row>
                            <Col md={4} className="control-label col-form-label">Source</Col>
                            <Col md={3}>
                                <select id="source" name="source" className="form-control" value={source} readOnly={formStatus!==0} onChange={(e) => setSource(e.target.value)}>
                                    <option value="1">Banners</option>
                                    <option value="2">Popups</option>
                                </select>
                            </Col>
                        </Row> 
                        <Row>
                            <Col md={4} className="control-label col-form-label">Campaign IDs</Col>
                            <Col md={5}>
                                <textarea className="form-control" id="campaignids" name="campaignids" readOnly={formStatus!==0} style={{height: 500 }} value={campaignids} onChange={(e) => setCampaignids(e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center">
                                <Card.Text>{formMessage}</Card.Text>
                                {formStatus === 0 ? <button className="btn btn-fill btn-primary">Update Campaigns</button> : null}
                                {formStatus === 2 ? <button className="btn btn-fill btn-primary" onClick={restart}>Start New Update</button> : null}
                            </Col>
                        </Row>
                    </Card.Body>
                    </Form>
                </Col>
                <Col md={6}>
                    <Form title="Bid Schedule" description="Enter bid values for each hour" action={{'function': clearBids, 'icon':FaTrash}} noCard={true}>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                {bidsByHour.slice(0,12).map((bid,hour) => (
                                <Row key={hour}>
                                    <Col md={6} className="control-label col-form-label-sm">{printHour(hour, false)}</Col>
                                    <Col md={6}>
                                        <InputGroup>
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <FormControl aria-label="Bid" type="number" id={`bid_${hour}`} name={`bid_${hour}`} value={bid} onChange={(e) => setBid(e)} readOnly={formStatus!==0} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                ))}  
                            </Col>
                            <Col md={6}>
                                {bidsByHour.slice(12).map((bid,hour) => (
                                <Row key={hour}>
                                    <Col md={6} className="control-label col-form-label-sm">{printHour(hour, true)}</Col>
                                    <Col md={6}>
                                        <InputGroup>
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <FormControl aria-label="Bid" type="number" id={`bid_${(hour+12)}`} name={`bid_${(hour+12)}`} value={bid} onChange={(e) => setBid(e)} readOnly={formStatus!==0} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                ))}  
                            </Col>
                        </Row>
                    </Card.Body>
                    </Form>
                </Col>
            </Row>
        </Card>
        <Card>
            <CardHeader title="Configured Campaigns" description="Campaigns which have previously been configured" />
            <DataTable hover={false} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None} />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(TJBidSchedulerTool), "Traffic Junky Auto-Bids"));
