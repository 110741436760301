import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import CardHeader from '../../../containers/CardHeader'

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_cam_models } from "../../../app/apiEndpoints"
import { tableTypes } from "../../../app/settings"
import DataTable from "../../../containers/DataTable";
import CamModelToolDetails from './CamModelToolDetails'
import { Table, Row, Col, Card } from 'react-bootstrap'

const CamModelTool = (props) => {
    const toolID = 1
    const [tool, setTool] = useState({})
    const [loading, setLoading] = useState(false)

    const [filterName, setFilterName] = useState('')
    const [filterGender, setFilterGender] = useState(0)
    const [filterModified, setFilterModified] = useState(0)
    const [filterHidden, setFilterHidden] = useState(0)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [models, setModels] = useState([])
    const [curModel, setCurModel] = useState(-1)
    const [model, setModel] = useState({})

    const tableID = "model"
    const tableFields = ["Model Username", "Gender", "PPM", "Hidden?", "Modified?"]

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const getFields = async () => {
            setLoading(true)
            const data = await toolsAPICall(tool_cam_models, { 'requester': userID, 'company': companyID })
            if(data?.models) setModels(data.models)
            setLoading(false)
        }
        getFields()
    }, [userID, companyID])

    const tableData = () => {
        if(models.length === 0) {
            return [];
        } 
        console.log("filter", filterName, filterGender, filterModified, filterHidden)
        let filteredModels = models.filter((model) => {
            if(filterName.length > 0 && !model.model.toLowerCase().includes(filterName.toLowerCase())) return false;
            if(filterGender !== 0 && filterGender !== model.gender) return false;
            if(filterModified === "1" && parseInt(model.modified) !== 1) return false;
            else if(filterModified === "2" && parseInt(model.modified) !== 0) return false;
            if(filterHidden === "1" && parseInt(model.hide) !== 1) return false;
            else if(filterHidden === "2" && parseInt(model.hide) !== 0) return false;
            return true
        })

        let rows = [];
        filteredModels.forEach(row => {
            rows.push({'id': row.id, 'values': [
                row.model,
                row.gender,
                row.ppm,
                parseInt(row.hide) === 1 ? "Hidden" : "",
                parseInt(row.modified) === 1 ? "Custom" : ""
            ]});
        });
        
        return rows
    }

    const onClick = (e) => {
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        setCurModel(rowID)

        const newModel = models.find(m => (m.id === rowID));

        if(newModel) {
            setModel(newModel)
        } else {
            setModel({})
        }
    }

    const updateModel = async (newModel) => {
        const modelIndex = models.findIndex(m => (m.id === curModel));
        models[modelIndex] = newModel
        setModels(models)
        setModel(newModel)
    }


    /*
    const updateCampaigns = (e) => {
        e.preventDefault()
        setFormMessage("")

        if(!bid || isNaN(parseFloat(bid)) || parseFloat(bid) < 0) {
            setFormMessage("Please enter a New Bid")
            return
        }

        // split rows and remove duplicates
        let ids = campaignids.split("\n")
        ids = ids.filter(function(item, pos) {
            return ids.indexOf(item) === pos;
        })

        // convert to objects
        const newCampaigns = ids.reduce((camps, cId) => {
            if(!camps.includes({'id': cId})) camps.push({'id': cId})
            return camps
        }, [])

        setUpdating(true)
        setCampaigns(newCampaigns)
        setCurCampaign(0)
    }
    */

    if(!models) return <></>

    return (
        <>
        <Row>
            <Col md={7}>
                <Card>
                    <CardHeader title={tool.tool_name} description={tool.description} />
                    {loading ? <Card.Body>Loading...</Card.Body> : 
                    <>
                    <Card.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Search</th>
                                    <th>Gender</th>
                                    <th>Hidden?</th>
                                    <th>Modified?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><input type="text" className="form-control" placeholder="Username" value={filterName} onChange={(e) => setFilterName(e.target.value)} /></td>
                                    <td>
                                        <select className="form-control" value={filterGender} onChange={(e) => setFilterGender(e.target.value)}>
                                            <option value="0">All</option>
                                            <option value="F">F</option>
                                            <option value="M">M</option>
                                            <option value="TS">TS</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-control" value={filterHidden} onChange={(e) => setFilterHidden(e.target.value)}>
                                            <option value="0">Show All</option>
                                            <option value="1">Hidden</option>
                                            <option value="2">Visable</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className="form-control" value={filterModified} onChange={(e) => setFilterModified(e.target.value)}>
                                            <option value="0">Show All</option>
                                            <option value="1">Modified</option>
                                            <option value="2">Unmodified</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                    <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} onClick={onClick} currentRowID={curModel} tableSettings={tableTypes.Tool} />
                    </>
                    }  
                </Card>
            </Col>
            <Col md={5}>
                <Card>
                    {model.id ? 
                    <CamModelToolDetails model={model} returnModel={updateModel}/> :
                    <CardHeader title="Cam Models" description="Select a model to update" /> 
                    }
                </Card>
            </Col>
        </Row>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(CamModelTool), "Cams Model Management"));
