import React from 'react';

import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import dashboardAPICall from '../../app/apiCalls/dashboardAPICall'
import { mb_dashboard_placements } from "../../app/apiEndpoints"
import DataTable from "../../containers/DataTable";
import CardHeader from '../../containers/CardHeader'
import { Breadcrumb, Card } from 'react-bootstrap'
import { tableTypes } from "../../app/settings";
import MediaBuyOptions from "./MediaBuyOptions";
import InvalidPermissions from '../../containers/InvalidPermissions'
import ContentNotFound from '../../containers/ContentNotFound'

import Moment from 'moment';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const MediaBuyAPIView = (props) => {
    let query = useQuery();
    const history = useHistory();
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    const [api, setAPI] = useState({})
    const [data, setData] = useState([])
    const [totals, setTotals] = useState({})
    let { apiURI } = useParams();
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const startDate = query.get("startDate")
    const endDate = query.get("endDate") 
    const [dataLoaded, setDataLoaded] = useState(false)
    const [hasError, setHasError] = useState(false)

    const tableFields = ["Placement", "# of Offers", "Total Spend", "Total Revenue", "Total Profit", "Profit Margin", "EPC"]

    useEffect(() => {
        const getDetails = async () => {
            if(apiURI.length > 0) {
                const data = await dashboardAPICall(mb_dashboard_placements, 
                    {'apiURI': apiURI, 
                        'requester': userID, 
                        'company': companyID, 
                        'startDate': startDate || (Moment().format('MM/DD/YYYY')), 
                        'endDate': endDate || (Moment().format('MM/DD/YYYY'))
                    })
                if(data.api) {
                    setAPI(data.api) 
                    if(data.data) {
                        setData(data.data)
                    }
                    if(data.totals) {
                        setTotals(data.totals)
                    }
                }
                else if(data.error) {
                    setHasError(true)
                }
                setDataLoaded(true)
            }
        }
        if(!dataLoaded)
            getDetails()
    }, [apiURI, userID, companyID, startDate, endDate, dataLoaded])

    const onClick = (e) => {
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${api.api_name}_`, "")
        history.push(`/mediabuy/${apiURI}/placement/${rowID}?startDate=${startDate || (Moment().format('MM/DD/YYYY'))}&endDate=${endDate || (Moment().format('MM/DD/YYYY'))}`)
    }

    const tableData = () => {
        console.log("dash data")
        if(data.length === 0) return []
        const dataRows = []
        data.forEach((row, i) => {
            dataRows.push({'id': row.placement_uri, 
                'values': [
                    `<a href="/mediabuy/${apiURI}/placement/${row.placement_uri}?startDate=${startDate || (Moment().format('MM/DD/YYYY'))}&endDate=${endDate || (Moment().format('MM/DD/YYYY'))}">${row.placement_name}</a>`,
                    row.offers,
                    row.totalCost,
                    row.totalRevenue,
                    row.totalProfit,
                    row.profitMargin,
                    row.EPC
                ]});
        });
        return dataRows
    }

    const csvData = () => {
        if(data.length === 0) return []
        const dataRows = []
        dataRows.push(tableFields)
        data.forEach((row, i) => {
            dataRows.push( [
                    row.placement_name,
                    row.offers,
                    row.totalCost,
                    row.totalRevenue,
                    row.totalProfit,
                    row.profitMargin,
                    row.EPC
                ]);
        });
        return dataRows
    }

    const csvFileName = () => {
        if(api.api_name.length === 0) return "APIs"
        return `${api.api_name.replaceAll(" ", "_")}.csv`
    }
    
    if(!isSysadmin) 
        return <InvalidPermissions />

    if(hasError) 
        return <ContentNotFound contentType="Dashboard" />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/mediabuy/">Media Buying Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active>{api.api_name}</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={api ? api.api_name : "Loading..."} description="Available placements for API" />
            {data?.length > 0 ?
            <>
            <Card.Body>
            <MediaBuyOptions 
                apiID={api.api_id}
                startDate={startDate || (Moment().format('MM/DD/YYYY'))}
                endDate={endDate || (Moment().format('MM/DD/YYYY'))}
                formAction={`/mediabuy/${apiURI}`}
                csvData={csvData()}
                csvFileName={csvFileName()} />
            </Card.Body>
            <DataTable 
                tableID={api.api_name} 
                fieldNames={tableFields} 
                dataRows={tableData()} 
                tableSettings={tableTypes.None} 
                totals={totals}
                hover={true}
                onClick={onClick} 
                />
            </>
            : <></>}
            {!dataLoaded ? <Card.Body>Loading dashboard...</Card.Body> : <></>}
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(MediaBuyAPIView), "Media Buy API Dashboards"));
