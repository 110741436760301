import React from 'react';
import { connect, useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import bannerAPICall from '../../app/apiCalls/bannerAPICall'

import CardHeader from '../../containers/CardHeader'
import DataTable from '../../containers/DataTable'

import { FaPlus } from "react-icons/fa";
import { banners_list } from '../../app/apiEndpoints';
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap';
import { tableTypes } from '../../app/settings';

const BannerView = (props) => {
    const [site, setSite] = useState({})
    const [company, setCompany] = useState({})
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [banners, setBanners] = useState([])
    let { siteURI, companyURI } = useParams();
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    let title = site ? company.company_name : "Advertiser";
    let subtitle = site ? `Banners for ${site.site_name}` : ""
    const headerAction = site && isSysadmin ? {'path': `/banners/${siteURI}/add`, 'icon':FaPlus} : {}

    const tableID = "banner"
    const tableFields = ["Banner", "Last Checked", ""]

    useMemo(() => {
        const getBanners = async () => {
            if(siteURI) {
                const data = await bannerAPICall(banners_list, {'siteURI': siteURI, 'companyURI': companyURI, 'requester': userID, 'company': companyID })
                if(data) {
                    if(data.site) setSite(data.site)
                    if(data.company) setCompany(data.company)
                    if(data.banners) setBanners(data.banners)
                    if(data.width) setWidth(data.width)
                    if(data.height) setHeight(data.height)
                }
            }
        }
        getBanners()
    }, [siteURI, companyURI, userID, companyID])

    const tableData = () => {
        if(banners.length === 0) {
            return [];
        } 
        let rows = [];
        banners.forEach(row => {
            rows.push({'id': row.banner_id, 'values': [
                `<a href="#${row.banner_id}">${row.banner_name}</a>`,
                row.last_track,
                `${isSysadmin ? `<a href="/banners/${siteURI}/edit/${row.banner_id}" class="btn btn-primary btn-sm">Edit</a>` : ''}`
            ]});
        });
        return rows
    }

    if(!banners) return <></>

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/banners/">Banners</Breadcrumb.Item>
          <Breadcrumb.Item href={`/banners/${siteURI}`}>{site.site_name}</Breadcrumb.Item>
          <Breadcrumb.Item active>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} id="Top" />
            <DataTable hover={false} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None}  />
        </Card>
        {banners.map((banner, b) => (
        <Card>
            <CardHeader title={banner.banner_name} description="Banners found in the last two days" id={banner.banner_id} />
            <Card.Body>
                
            <Row>
            {
            banner.tracks.length > 0 ? banner.tracks.map((file, t) => (
                    <Col className="text-center">
                    {['jpg','jpeg','png','gif'].includes(file.file_type) ?
                        <img loading="lazy" src={`data:image/${file.file_type};base64,${file.file}`} alt={file.file_name}  width="auto" height="auto" key={file.image_id} />
                        : <></>}
                    {['mp4','ogg'].includes(file.file_type) ?
                        <video alt={file.file_name}  width={width > 0 ? width : 'auto'} height={height > 0 ? height : 'auto'} key={file.image_id} controls>
                            <source src={(file.file_name)} type="video/mp4"></source>
                        </video>
                        : <></>}
                    {/* {['html'].includes(file.file_type) ?
                        (file.file_name.includes("toplineads") ?
                        <iframe loading="lazy" rel="sponsored noopener nofollow noindex" src={(file.file_name)} key={file.image_id} title={banner.banner_name} width={width > 0 ? width : 'auto'} height={height > 0 ? height : 'auto'} ></iframe> :
                        <iframe loading="lazy" rel="sponsored noopener nofollow noindex" srcDoc={Buffer.from(file.file, 'base64')} key={file.image_id} title={banner.banner_name} width={width > 0 ? width : 'auto'} height={height > 0 ? height : 'auto'} ></iframe>)
                        : <></>} */}
                        <p align="center"><a href={file.file_name} title={file.file_name} target="_blank" rel="noreferrer" className='btn btn-secondary btn-sm'>View</a></p>
                    
                    </Col>
            )) : <></>}
                </Row>
                <p align="center"><a href="#Top" className='btn btn-primary btn-sm'>Back to Top</a></p>
            </Card.Body>
        </Card>
        ))}
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(BannerView), "Banners"));
