import React from 'react';

import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';

import DataTable from '../../containers/DataTable'

import bannerAPICall from '../../app/apiCalls/bannerAPICall'
import { banner_site_list } from '../../app/apiEndpoints'
import { tableTypes } from '../../app/settings';

const BannerTable = (props) => {
    const history = useHistory();
    const [data, setData] = useState([])

    const tableID = "banner"
    const tableFields = ["Site", "# of Banners", ""]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentBannerID)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    useMemo(() => {
        const getData = async () => {
            const data = await bannerAPICall(banner_site_list, {'requester': userID, 'company': companyID })
            if(data && data.sites)
                setData(data.sites)
        }
        getData()
    }, [userID, companyID])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.site_uri, 'values': [
                row.site_name,
                row.banner_count,
                `${isSysadmin ? `<a href="/banners/${row.site_uri}/edit" class="btn btn-primary btn-sm">Edit</a>` : ''}`
            ]});
        });
        return rows
    }

    const onClick = (e) => {
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`banner_`, "")
        history.push(`/banners/${rowID}`)
    }

    return (
        <>
        <DataTable 
        hover={true} 
        tableID={tableID} 
        onClick={onClick} 
        fieldNames={tableFields} 
        dataRows={tableData()} 
        currentRowID={rowID}
        tableSettings={tableTypes.None} 
        />
        </>
    )
}


export default connect(state => ({...state}))(BannerTable)