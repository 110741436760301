import React from 'react';

export default function SideMenuLink(props) {

    return (
        <li className={`nav-item ${(props.active ? 'active' : '')}`}>
            <a className="nav-link" href={props.linkPath}>
                <props.icon size={24}/> 
                <p>{props.linkName}</p>
            </a>
        </li>
    );
}
