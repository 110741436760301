import React from 'react';

import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContentWithDetails from '../../containers/pageContentWithDetails'

import APITable from './APITable'
import APIDetails from './APIDetails'
import CardHeader from '../../containers/CardHeader'

import { FaPlus } from "react-icons/fa";
import { Card } from 'react-bootstrap'

const APISection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const title = "Client APIs";
    const subtitle = "Listing of current Client APIs"
    const headerAction = isSysadmin ? {'path': "/apis/add", 'icon':FaPlus} : {}

    return (
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <APITable showDetailsOnClick={true} />
        </Card>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContentWithDetails(APISection, APIDetails), "APIs"));
