import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import AdminDashboard from './AdminDashboard'
import UserDashboard from './UserDashboard'
import ReportList from '../Reports/ReportList'

const DashboardSection = (props) => {
    const history = useHistory();
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const userGroup = useSelector((state) => state.user.userGroup)

    if(window.location.pathname !== "/logout/" && userGroup === '4') {
        history.push("/reports/")
    }   
    

    return (
        <>
        {isSysadmin && <AdminDashboard />}
        {!isSysadmin ? (userGroup === '4' ? <ReportList /> : <UserDashboard />) : <></>}
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(DashboardSection), "Dashboard"));
