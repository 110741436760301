import React from 'react';

import { Card } from 'react-bootstrap';

const DataLoading = ({contentType}) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>Loading {contentType}</Card.Title>
                <Card.Text>Please wait while we load the requested {contentType}</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default DataLoading;
