import React from 'react';

import { Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import { SITE_NAME } from '../../app/globals'
import { topMenuLinks } from '../../app/topMenuLinks'
import TopMenuLink from './TopMenuLink'

function TopMenu(props) {
    return (
        <nav className="navbar navbar-expand-lg " color-on-scroll="500">
            <Container fluid>
                <a className="navbar-brand" href="#top"> {props.title ? props.title : SITE_NAME} </a>
                <button href="" className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-bar burger-lines"></span>
                    <span className="navbar-toggler-bar burger-lines"></span>
                    <span className="navbar-toggler-bar burger-lines"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navigation">
                    <ul className="nav navbar-nav mr-auto">
                    </ul>
                    <ul className="navbar-nav ml-auto">
                    {topMenuLinks.map((link, i) => (
                        ((link.reqLogin && !props.token) || (link.reqAdmin && !(props.user.isSysadmin || props.isViewingAsClient)) 
                            ? '' : 
                            <TopMenuLink linkPath={link.path} linkName={link.name} key={`tl_${i}`} />
                        )
                    ))}
                    </ul>
                </div>
            </Container>
        </nav>
    )
}

export default connect(state => ({...state}))(TopMenu);
