import React from 'react';
import { Card } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux'

const ProfileSide = () =>  {
    const user = useSelector((state) => state.user)

    return (
        <>
        <Card className="card-user">
            <div className="image">
                <img src={`/assets/img/logos/${user.company.logoImg?.length > 0 ? user.company.logoImg : "default.png"}`} alt="..." />
            </div>
            <Card.Body>
                <Card.Text className="text-center author">
                    <img className="avatar border-gray" src={`/assets/img/faces/${user.iconImg?.length > 0 ? user.iconImg : "default.jpg"}`} alt="..." />
                </Card.Text>
                <h4 className="title text-center ">{user.name}</h4>
                <Card.Text className="text-center description">
                        {user.email}
                </Card.Text>
                <Card.Text className="description text-center">
                    <strong>{user.company.name}</strong>
                    {user.company.address1 && <br/>}{user.company.address1}
                    {user.company.address2 && <br/>}{user.company.address2}
                </Card.Text>
            </Card.Body>
        </Card>
        </>
    )

}

export default connect(state => ({...state}))(ProfileSide);
