import React from 'react';

import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import apisAPICall from '../../app/apiCalls/apisAPICall'

import { BANNER_SITE_URL } from '../../app/globals'
import { FaEdit } from "react-icons/fa";
import CardHeader from '../../containers/CardHeader';
import CardSectionTitle from '../../containers/CardSectionTitle';
import { api_details } from '../../app/apiEndpoints';
import { Accordion, Card } from 'react-bootstrap';

const APIDetails = (props) => {
    const [API, setAPI] = useState({})
    const apiID = useSelector((state) => state.currentAPIID)
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    const headerAction = isSysadmin ? {'path': `/apis/${apiID}`, 'icon':FaEdit} : {}

    useMemo(() => {
        const getAPI = async () => {
            const data = await apisAPICall(api_details, {'apiID': apiID, 'requester': userID, 'company': companyID })
            setAPI(data.api)
        }
        if(parseInt(apiID) > 0)
            getAPI()
    }, [apiID, userID, companyID])

    if(!apiID) return <></>


    return (
        <Card>
            <CardHeader title={API.api_name} description={API.company_name} action={headerAction} />
            <Card.Body>
                <Accordion>
                    <Card>
                        <CardSectionTitle title="API Feeds" />
                        <Card.Body>
                            <a href={`${BANNER_SITE_URL}${API.company_key}/${API.api_code}/${API.api_code}.json`} target="_blank" rel="noreferrer" title="View JSON">View JSON</a>
                            <br/>
                            <a href={`${BANNER_SITE_URL}${API.company_key}/${API.api_code}/${API.api_code}.xml`} target="_blank" rel="noreferrer" title="View XML">View XML</a>    
                        </Card.Body>
                    </Card>
                    <Card>
                        <CardSectionTitle title="Admin" />
                        <Card.Body>
                            <a href="/tools/" title="Management">Management</a>
                            <br/>
                            <a href={`${BANNER_SITE_URL}${API.company_key}/${API.api_code}/doc`} target="_blank" rel="noreferrer" title="Documentation">Documentation</a>
                        </Card.Body>
                    </Card>
                </Accordion>
            </Card.Body>
        </Card>
    )
}

export default connect(state => ({...state}))(APIDetails)