import React from 'react';

import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { CSVDownload } from "react-csv";

import reportAPICall from '../../app/apiCalls/reportAPICall'
import { report_details_from_uri, report_data } from "../../app/apiEndpoints"

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ReportDownload = (props) => {
    let query = useQuery();

    const [report, setReport] = useState({})
    const [reportData, setReportData] = useState([])
    let { reportURI } = useParams();
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const startDate = query.get("startDate")
    const endDate = query.get("endDate")
    const reportSource = query.get("source")

    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        const getDetails = async () => {
            if(reportURI.length > 0) {
                const data = await reportAPICall(report_details_from_uri, {'reportURI': reportURI, 'requester': userID, 'company': companyID })
                setReport(data.report)
            }
        }
        getDetails()
    }, [reportURI, userID, companyID])

    useEffect(() => {
        const getData = async () => {
            if(report.report_id > 0 && (parseInt(report.has_date_range) === 0 || (startDate.length > 0 && endDate.length > 0))) {
                const data = await reportAPICall(report_data, {'reportID': report.report_id, 'startDate': startDate, 'endDate': endDate, 'source': reportSource })
                setReportData(data.data)
                setDataLoaded(true)
            }
        }
        if(!dataLoaded)
            getData()
    }, [report, startDate, endDate, reportSource, dataLoaded])

    const tableFields = () => {
        if(reportData.length === 0) return []
        const row = reportData[0]
        const fields = Object.keys(row).map((field) => field)
        return fields
    }

    const csvData = () => {
        if (reportData.length === 0) return [];
        const dataRows = [];
    
        dataRows.push(tableFields())
        reportData.forEach((row) => {
            const values = [];
            Object.keys(row).forEach((field) => {
                let value = row[field];
    
                // Replace commas and quotes with HTML entities
                if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
                    value = value.replace(/,/g, ';');
                }
    
                values.push(value);
            });
            dataRows.push(values);
        });
    
        return dataRows;
    };  
    

    const csvFileName = () => {
        if(report.report_name.length === 0) return "Report"
        return `${report.report_name.replaceAll(" ", "_")}.csv`
    }

    if(!dataLoaded) return <></>
    return ( 
        <CSVDownload 
            filename={csvFileName()}
            target="_blank"
            data={csvData()} 
            headers={tableFields()} />  
    )
    
}

export default connect(state => ({...state}))(ReportDownload);
