import React from 'react';

import { connect } from 'react-redux'
//import { useHistory } from 'react-router-dom';

import { ResponsiveBar } from '@nivo/bar';

import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'
import InvalidPermissions from '../../containers/InvalidPermissions';
import landerAPICall from '../../app/apiCalls/landerAPICall';
import { lander_details, lander_details_data } from '../../app/apiEndpoints';
import DataLoading from '../../containers/DataLoading';
import ContentNotFound from '../../containers/ContentNotFound';
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap';
import CardHeader from '../../containers/CardHeader';
import Button from '../../containers/Form/Button';

import { CSVLink } from "react-csv";

const LanderView = (props) => {
    let { lpID } = useParams();
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)


    const [status, setStatus] = useState(0)
    const [keys, setKeys] = useState([])
    const [data, setData] = useState([])
    const [stats, setStats] = useState([])
    const [userStats, setUserStats] = useState([])
    const [stepkeys, setStepkeys] = useState([])
    const [lander, setLander] = useState([])

    const [revCsvStatus, setRevCsvStatus] = useState(0)
    const [revCsv, setRevCsv] = useState({})

    useEffect(() => {
        const getData = async () => {
            setStatus(1);
            try {
                const data = await landerAPICall(lander_details, {
                    requester: userID,
                    company: companyID,
                    lpID: lpID,
                });
                setData(data.steps);
                setKeys(data.keys);
                setStats(data.stats);
                setUserStats(data.userStats);
                setStepkeys(data.stepkeys);
                setLander(data.lander);
                setStatus(2);
            } catch (error) {
                console.error('Error fetching data:', error);
                setStatus(-1); // Optional: Set an error status
            }
        };
    
        if (status === 0) {
            getData();
        }
    }, [lpID, userID, companyID, status]);
    
    const genReport = async (e) => {
        e.preventDefault()
        setRevCsvStatus(1)

        const csvData = await landerAPICall(lander_details_data, {
            requester: userID,
            company: companyID,
            lpID: lpID,
        });
        if(csvData.revcsv) { 
            setRevCsv(csvData.revcsv)
            setRevCsvStatus(2)
        }
    }

    if(!isSysadmin) return <InvalidPermissions />

    if(status !== 2) return <DataLoading contentType={"Landing Page"} />
    else if(status === 2 && (!data || data.length === 0)) return <ContentNotFound />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/landers/">Landing Pages</Breadcrumb.Item>
          <Breadcrumb.Item active>{lander.lander}</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={lander ? lander.lander : "Loading..."} description="Landing page drop off step counts" />
            <Card.Body>
        <div style={{ height: '400px' }}>
<ResponsiveBar
        data={data}
        keys={keys}
        indexBy="step"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Steps',
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '# of Drop Offs',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="Landing Page Drop Off Counts"
        barAriaLabel={e=>e.id+": "+e.formattedValue+" drop offs in step: "+e.indexValue}
    />
    </div>

    <p align="center">
        { revCsvStatus === 0 && // default
            <Button title="Download All Data" className="secondary" onClick={genReport} /> }
        { revCsvStatus === 1 && // generating has begun
            <small>Generating Report...</small> }
        { revCsvStatus === 2 && revCsv.file && // download now available
        <CSVLink 
        data={revCsv.data}
        filename={revCsv.file}
        className="btn btn-primary text-center"
        target="_blank">Export Data</CSVLink>}
    </p>
    </Card.Body>
        </Card>
        <Card>
            <Card.Body>
                <Row>
                    <Col md={6}>
                    <h4 className="title mb-3" id="">Lander Stats</h4>
                <Row>
                    <Col md={6}>Success to Affiliate</Col>
                    <Col md={3}>{stats.AFFILIATE ?? 0}</Col>
                    <Col md={3}>{stats.AFFILIATE_RATE ?? 0}</Col>
                </Row>
                <Row className="pb-3">
                    <Col md={6}>Success to Redirect</Col>
                    <Col md={3}>{stats.REDIRECT ?? 0}</Col>
                    <Col md={3}>{stats.REDIRECT_RATE ?? 0}</Col>
                </Row>
                <Row>
                    <Col md={12} style={{ borderTop: '1px solid black' }} className='pt-2 pb-2'></Col>
                </Row>
                <Row>
                    <Col md={6}>Total Success</Col>
                    <Col md={3}>{stats.SUCCESS ?? 0}</Col>
                    <Col md={3}>{stats.SUCCESS_RATE ?? 0}</Col>
                </Row>
                <Row className="pb-3">
                    <Col md={6}>Failed</Col>
                    <Col md={3}>{stats.FAILED ?? 0}</Col>
                    <Col md={3}>{stats.FAILED_RATE ?? 0}</Col>
                </Row>
                <Row>
                    <Col md={12} style={{ borderTop: '1px solid black' }} className='pt-2 pb-2'></Col>
                </Row>
                <Row>
                    <Col md={6}>Total Registrations</Col>
                    <Col md={3}>{stats.TOTAL ?? 0}</Col>
                </Row>

                <h4 className="title mb-3 mt-5" id="">User Stats</h4>
            { userStats.length > 0 ?
            <>
                        <Row>
                            <Col md={4}>
                                <strong>Platform</strong>
                            </Col>
                            <Col md={2}>
                            <strong>Affiliate</strong>
                            </Col>
                            <Col md={2}>
                            <strong>Redirect</strong>
                            </Col>
                            <Col md={2}>
                            <strong>Failed</strong>
                            </Col>
                            <Col md={2}>
                            <strong>Total</strong>
                            </Col>
                        </Row>
                    {userStats.map((stat,key) => (
                        <Row key={key}>
                            <Col md={4}>
                                {stat.platform}
                            </Col>
                            <Col md={2}>
                                {stat.AFFILIATE}
                            </Col>
                            <Col md={2}>
                                {stat.REDIRECT}
                            </Col>
                            <Col md={2}>
                                {stat.FAILED}
                            </Col>
                            <Col md={2}>
                                {stat.TOTAL}
                            </Col>
                        </Row>
                    ))}
                    </> : '' 
                }
                    </Col>
                    <Col md={1}>&nbsp;</Col>
                    <Col md={5}>
                    <h4 className="title mb-3" id="">Lander Steps</h4>
            { stepkeys.length > 0 ?
                    stepkeys.map((step,s) => (
                        <Row key={step.name}>
                            <Col md={2}>
                                {step.step}
                            </Col>
                            <Col md={10}>
                                {step.name}
                            </Col>
                        </Row>
                    )) : '' 
                }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(LanderView), "Lander Data"));
