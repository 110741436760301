import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import CardHeader from '../../../containers/CardHeader'
import Form from '../../../containers/Form/Form'
import Button from '../../../containers/Form/Button'

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_campaign_optimizer_list, tool_campaign_optimizer_save } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import { FaPlus } from "react-icons/fa";
import { Card, Row, Col, Breadcrumb } from 'react-bootstrap'
import { tableTypes } from '../../../app/settings'
import Select from '../../../containers/Form/Select'
import Input from '../../../containers/Form/Input'

const CampaignOptimizerTool = (props) => {
    const history = useHistory();
    const toolID = 14
    const [tool, setTool] = useState({})
    const [id, setId] = useState(0)
    const [campaigns, setCampaigns] = useState([])
    const [type, setType] = useState(1)
    const [active, setActive] = useState(1)
    const [campaignid, setCampaignid] = useState('')
    const [formMessage, setFormMessage] = useState('')
    const [updating, setUpdating] = useState(false)
    const [processing, setProcessing] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const tableID = "camp"
    const tableFields = ["Campaign ID", "Campaign Name", "Type", "Start Date", "Active"]

    const typeOptions = [{'ID': 1, 'NAME': "eCPM"},{'ID': 2, 'NAME': "CTR"}]


    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_campaign_optimizer_list, { 'requester': userID })
                if(data.campaigns)
                    setCampaigns(data.campaigns)
            }
        }
        getDetails()
    }, [userID])


    useEffect(() => {
        const performUpdate = async () => {
            setProcessing(true)

            const data = await toolsAPICall(tool_campaign_optimizer_save, { 'id': id, 'campaignid': campaignid, 'type': type, 'active': active, 'requester': userID })
            if(data.error) {
                setFormMessage(data.error)
            } 
            else {
                if(data.id) {
                    setId(data.id)
                }
                if(data.campaigns) {
                    setCampaigns(data.campaigns)
                }
                setFormMessage("Campaign saved")
            }
            setUpdating(false)
            setProcessing(false)
        }
        
        if((campaignid).length > 0 && updating && !processing) {
            performUpdate()
        }
    }, [campaigns, id, campaignid, type, active, updating, processing, userID])

    const tableData = () => {
        if(campaigns.length === 0) {
            return [];
        } 
        //console.log('data', campaigns)
        let rows = [];
        campaigns.forEach(row => {
            rows.push({'id': `${row.campaign_id}`, 'viewFnc': openCampaign, 'editFnc': viewCampaign, 'values': [
                row.campaign_id,
                row.campaign_name,
                row.type === "1" ? "eCPM" : "CTR",
                row.start_date, 
                row.isactive ? "Yes" : "No"
            ]});
        });
        return rows
    }

    const viewCampaign = (e) => {
        let rowID = e.target.name
        rowID = rowID.replace(`edit_`, "")
        //look for the subpid in array
        let r = campaigns.find((element) => {
            return element.campaign_id === rowID;
          })
        if(r === undefined) {
            return
        }
        setId(r['campaign_id'])
        setCampaignid(r['campaign_id'])
        setType(r['type'])
        setActive(r['isactive'])
    }

    const addCap = (e) => {
        setId(0)
        setCampaignid("")
        setType(1)
        setActive(1)
    }

    const updateCampaign = (e) => {
        e.preventDefault()
        setFormMessage("")

        if(!campaignid) {
            setFormMessage("Please enter a Campaign ID")
            return
        }

        setUpdating(true)
    }

    const openCampaign = (e) => {
        e.preventDefault()
        let rowID = e.target.name
        rowID = rowID.replace(`view_`, "")
        if(parseInt(rowID) > 0) {
            setId(parseInt(rowID))
        }
        history.push(`/tools/view/campaign-optimizer/${rowID}`)
    }

    if(!tool) return <></>
    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/tools/">Tools</Breadcrumb.Item>
          <Breadcrumb.Item active>{tool.tool_name}</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title="Campaign Optimizing" description="Current Campaigns set for optimizing" action={{'function': addCap, 'icon':FaPlus}} />
                    <DataTable tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None} viewFnc={openCampaign} hover={true} />
                </Card>
            </Col>
        </Row>
            <Form title={tool.tool_name} description={tool.description} onSubmit={updateCampaign}> 
        <Row>
            <Col md={12}> 
                <Card.Body>
                    <Row>
                        <Col lg={2} md={2} className="control-label col-form-label">Campaign ID</Col>
                        <Col lg={4} md={10}>
                            <Input className="form-control" id="campaignid" name="campaignid" readOnly={updating || id > 0} value={campaignid} onChange={(e) => setCampaignid(e.target.value)} placeholder='Campaign ID' />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={2} md={2} className="control-label col-form-label">Campaign Type</Col>
                        <Col lg={4} md={4}>
                            <Select value={type} name="type" onChange={(e) => setType(e.target.value)} validate="required|numeric" colSize={12} readOnly={updating} formValues={typeOptions} useDefault={false} />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={2} md={2} className="control-label col-form-label">Active?</Col>
                        <Col lg={4} md={4}>
                            <Select value={active} name="active" onChange={(e) => setActive(e.target.value)} validate="required|numeric" colSize={12} readOnly={updating} formField="yesno" useDefault={false} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="text-center pt-5">
                            <Card.Text>{formMessage}</Card.Text>
                            {!updating ? <Button title={(id > 0 ? "Update Campaign" : "Add Campaign")} className="info" /> : null}
                        </Col>
                    </Row>
                </Card.Body>
            </Col>
        </Row>
        </Form>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(CampaignOptimizerTool), "Campaign Optimizer Tool"));