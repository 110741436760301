import React from 'react';

import { connect, useSelector, useDispatch } from 'react-redux'
import { useState, useMemo } from 'react'
import { useParams, withRouter } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import reportAPICall from '../../app/apiCalls/reportAPICall'
import { selectReport } from '../../app/store/actions/reportActions'

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";
import Select from "../../containers/Form/Select";

import { SITE_NAME } from '../../app/globals'
import { report_details, report_save } from '../../app/apiEndpoints';
import InvalidPermissions from '../../containers/InvalidPermissions';
import ContentNotFound from '../../containers/ContentNotFound';

const ReportForm = (props) => {
    let { reportID } = useParams();

    const [report, setReport] = useState({})

    const [inputValue, setInputValue] = useState({ company: 0, name: "", description: "", uri: "", dateRange: 0, isActive: 1 });
    const [errors, setErrors] = useState({ company: "", name: "", description: "", uri: "", dateRange: "", isActive: "" });
    const { company, name, description, uri, dateRange, isActive } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [loading, setLoading] = useState(true)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const newReport = !report.report_id
    const canEdit = isSysadmin

    const dispatch = useDispatch();

    useMemo(() => {
        const getDetails = async () => {
            if(parseInt(reportID) > 0) {
                const data = await reportAPICall(report_details, {'reportID': reportID, 'requester': userID, 'company': companyID })
                if(data.report.report_id) {
                    const values = { company: data.report.company_id, name: data.report.report_name, description: data.report.description, uri: data.report.uri, dateRange: data.report.has_date_range, isActive: data.report.isactive }
                    setInputValue(values)
                    setReport(data.report)
                } else if(data.error) {
                    setFormMessage(data.error)
                    setHasErrors(true)
                }
                if(data.found && data.found === "0") {
                    setReport({ missing: true })
                }
                setLoading(false)
            }
        }
        console.log('report', reportID)
        if(parseInt(reportID) > 0 && (report === undefined || report === null || !report.report_id) && loading) {
            getDetails()
        } else if(reportID === "add") {
            setLoading(false)
        }
    }, [reportID, userID, companyID, report, loading])

    const saveReport = async (e) => {
        e.preventDefault();
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await reportAPICall(report_save, {
            'reportID': report.report_id ? report.report_id : 0, 
            'requester': userID, 
            'company': company, 
            'name': name, 
            'description': description, 
            'uri': uri, 
            'dateRange': dateRange, 
            'isActive': isActive 
        })

        //if successful, delete from project object
        if(data.error) {
            setHasErrors(true)
            setFormMessage(data.error)
            return
        } 

        if(data.report?.report_id) reportID = data.report.report_id
        setReport(data.report)
        
        console.log(props)
        setFormMessage("Report has been saved")
        window.history.replaceState(null, SITE_NAME, `/reports/${reportID}`);
        dispatch(selectReport(reportID))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
          setErrors((prev) => ({
              ...prev,
              [field]: error,
          }));
    };

    if(reportID === "add" && !isSysadmin)
        return <InvalidPermissions />

    if(report.missing)
        return <ContentNotFound contentType="Report" />

    return (
        <>
        <Form title={!newReport ? "Edit Report" : "Add Report"}  description="" onSubmit={saveReport} >
            { !loading ?
            <>
            <Select
                value={company}
                label="Company"
                name="company"
                formField="companies"
                useDefault={true}
                onChange={handleChange}
                onError={handleError}
                validate="numeric|required"
                colSize="6"
                readOnly={!canEdit}
            />

            <Input
                type="text"
                value={name}
                placeholder="Report Name"
                label="Report Name"
                name="name"
                onChange={handleChange}
                onError={handleError}
                validate="name|required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={description}
                placeholder="Description"
                label="Description"
                name="description"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={uri}
                placeholder="Report URI"
                label="Report URI"
                name="uri"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="3"
                readOnly={!canEdit}
            />
            <Select
                value={dateRange}
                label="Has Date Range?"
                name="dateRange"
                formField="yesno"
                onChange={handleChange}
                onError={handleError}
                validate="numeric"
                colSize="3"
                readOnly={!canEdit}
            />
            <Select
                value={isActive}
                label="Active?"
                name="isActive"
                formField="yesno"
                onChange={handleChange}
                onError={handleError}
                validate="numeric"
                colSize="3"
                readOnly={!canEdit}
            />
            {canEdit && 
            <FormFooter formButton={{title: "Save Report", class: "primary"}} center={true} formMessage={formMessage} hasErrors={hasErrors} /> }
            </>
            : <p>Loading form data...</p> }
        </Form>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectReport: (reportID) => { 
            dispatch(selectReport(reportID))
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(pageWithSideMenu(pageContent(withRouter(ReportForm)), "Report Form"));
