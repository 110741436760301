import React from 'react';

import { useSelector } from 'react-redux'

import ReportTable from './ReportTable'
import CardHeader from '../../containers/CardHeader'

import { FaPlus } from "react-icons/fa";
import { Card } from 'react-bootstrap'

const ReportList = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const title = "Client Reports";
    const subtitle = "Listing of all Client Reports"
    const headerAction = isSysadmin ? {'path': "/reports/add", 'icon':FaPlus} : {}

    return (
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <ReportTable showDetailsOnClick={true} />
        </Card>
    )
}

export default ReportList;