import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import Form from '../../../containers/Form/Form'
import Button from '../../../containers/Form/Button'

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_subpid_exc_list, tool_subpid_exc_save, tool_subpid_exc_delete } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import { Card, Row, Col, Breadcrumb } from 'react-bootstrap'
import { tableTypes } from '../../../app/settings'
import ConfirmDialog from '../../../containers/ConfirmDialog';
import CascadeToolToggle from '../../../containers/CascadeToolToggle';

const SubpidExclusionTool = (props) => {
    const toolID = 11
    const [tool, setTool] = useState({})

    const [cascadeType, setCascadeType] = useState(0)

    const [id, setId] = useState(0)
    const [pathid, setPathid] = useState(0)
    const [subpid, setSubpid] = useState('')
    const [subpidMatch, setSubpidMatch] = useState(true)
    const [geo, setGeo] = useState('ALL')
    const [geoMatch, setGeoMatch] = useState(true)
    const [device, setDevice] = useState('ALL')
    const [deviceMatch, setDeviceMatch] = useState(true)
    const [formMessage, setFormMessage] = useState('')
    const [updating, setUpdating] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [exclusions, setExclusions] = useState([])
    const [geos, setGeos] = useState([])
    const [pathOptions, setPathOptions] = useState([])

    const tableID = "exc"
    const tableFields = ["Path", "Subpid", "Geo"]

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_subpid_exc_list, { 'requester': userID, 'cascadeType': cascadeType })
                setExclusions(data.exclusions)
                setGeos(data.geos)
                setPathOptions(data.paths)
            }
        }
        getDetails()
    }, [userID, cascadeType])


    useEffect(() => {
        const performUpdate = async () => {
            setProcessing(true)

            const data = await toolsAPICall(tool_subpid_exc_save, { 'id': id, 'subpid': subpid, 'subpidMatch': subpidMatch, 'pathid': pathid, 'geo': geo, 'geoMatch': geoMatch, 'device': device, 'deviceMatch': deviceMatch, 'requester': userID, 'cascadeType': cascadeType })
            if(data.error) {
                setFormMessage(data.error)
            } 
            else {
                if(data.id) {
                    setId(data.id)
                }
                if(data.exclusions) {
                    setExclusions(data.exclusions)
                }
                setFormMessage("Exclusion saved")
            }
            setUpdating(false)
            setProcessing(false)
        }
        
        if((subpid).length > 0 && updating && !processing) {
            performUpdate()
        }
    }, [exclusions, id, subpid, subpidMatch, geo, pathid, geoMatch, device, deviceMatch, exclusions, updating, processing, userID, cascadeType])

    const tableData = () => {
        if(exclusions.length === 0) {
            return [];
        } 
        //console.log('data', campaigns)
        let rows = [];
        exclusions.forEach(row => {
            rows.push({'id': `${row.ex_id}`, 'deleteFnc': confirmDeleteExclusion, 'values': [
                row.path,
                (row.subpid_match === "1" ? "IS" : "IS NOT") + ' ' + row.subpid,
                (row.geo_match === "1" ? "IS" : "IS NOT") + ' ' + row.geo,
                (row.devicetype_match === "1" ? "IS" : "IS NOT") + ' ' + row.devicetype
            ]});
        });
        return rows
    }

    const viewExclusion = (e) => {
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        //look for the subpid in array
        let r = exclusions.find((element) => {
            return element.ex_id === rowID;
          })
        if(r === undefined) {
            return
        }
        setId(r['ex_id'])
        setSubpid(r['subpid'])
        setSubpidMatch(r['subpid_match'])
        setPathid(r['pathid'])
        setGeo(r['geo'])
        setGeoMatch(r['geo_match'])
        setDevice(r['devicetype'])
        setDeviceMatch(r['devicetype_match'])
    }

    const addExclusion = (e) => {
        setId(0)
        setSubpid("")
        setSubpidMatch(1)
        setPathid(0)
        setGeo('ALL')
        setGeoMatch(1)
        setDevice('')
        setDeviceMatch(1)
    }

    const updateExclusion = (e) => {
        e.preventDefault()
        setFormMessage("")

        if(!subpid) {
            setFormMessage("Please enter a Subpid")
            return
        }
        if(!pathid) {
            setFormMessage("Please selet a Path")
            return
        }

        setUpdating(true)
    }

    const confirmDeleteExclusion = (e) => {
        e.preventDefault()
        console.log(e)
        let rowID = e.target.id
        rowID = rowID.replace(`delete_`, "")
        if(parseInt(rowID) > 0) {
            setId(parseInt(rowID))
            setShowConfirmDelete(true)
        }
    }

    const deleteExclusion = async () => {
        setShowConfirmDelete(false)
        if(id <= 0) return;
        const data = await toolsAPICall(tool_subpid_exc_delete, {
            'requester': userID, 
            'company': companyID, 
            'id': id,
            'cascadeType': cascadeType
        })

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            return
        } 

        if(data.exclusions) {
            setExclusions(data.exclusions)
        }
        addExclusion()
    }
    const filterCascade = (e) => {
        let { value } = e.target;
        setCascadeType(value)
    }

    if(!tool) return <></>
    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/tools/">Tools</Breadcrumb.Item>
          <Breadcrumb.Item active>{tool.tool_name}</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col md={12}>
                <Card>
                <CascadeToolToggle title="Existing Exclusions" description="Current Exclusions being used on Smart Landers" value={cascadeType} onChange={filterCascade} />
                    <DataTable tableID={tableID} fieldNames={tableFields} hideTotal={true} dataRows={tableData()} tableSettings={tableTypes.None} onClick={viewExclusion} hover={true} />
                    <Row>
                        <Col md={12} className="text-center"><Button onClick={addExclusion} title="Add New Exclusion" className="primary" /></Col>
                    </Row>
                </Card>
            </Col>
        </Row>
            <Form title={tool.tool_name} description={tool.description} onSubmit={updateExclusion}> 
        <Row>
            <Col md={12}> 
                <Card.Body>
                    <Row>
                        <Col md={1} className="control-label col-form-label">Path</Col>
                        <Col md={4}>
                            <select id="pathid" className="form-control" value={pathid} readOnly={updating} onChange={(e) => setPathid(e.target.value)}>
                                <option value="0">-- Path --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`p_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                        <Col md={2} className="control-label col-form-label text-left pt-5"><b>Rules</b></Col>
                    <Row>
                    </Row>
                    <Row>
                        <Col md={1} className="control-label col-form-label">Subpid</Col>
                        <Col md={2}>
                            <select id="subpidMatch" className="form-control" value={subpidMatch} readOnly={updating} onChange={(e) => setSubpidMatch(e.target.value)}>
                                <option value={1} key={"sm_1"}>IS</option>
                                <option value={0} key={"sm_0"}>IS NOT</option>
                            </select>
                        </Col>
                        <Col md={4} sm={2}>
                            <textarea className="form-control" id="subpid" name="subpid" readOnly={updating} style={{height: 200 }} value={subpid} onChange={(e) => setSubpid(e.target.value)} placeholder='Enter subpids on separate lines' />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={1} className="control-label col-form-label">Geo</Col>
                        <Col md={2}>
                            <select id="geoMatch" className="form-control" value={geoMatch} readOnly={updating} onChange={(e) => setGeoMatch(e.target.value)}>
                                <option value={1} key={"gm_1"}>IS</option>
                                <option value={0} key={"gm_0"}>IS NOT</option>
                            </select>
                        </Col>
                        <Col md={4} sm={8}>
                            <select id="geo" className="form-control" value={geo} readOnly={updating} onChange={(e) => setGeo(e.target.value)}>
                                {geos.map((option, o) => (
                                <option value={option.country} key={`geo_${o}`}>{option.country}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={1} className="control-label col-form-label">Device</Col>
                        <Col md={2}>
                            <select id="deviceMatch" className="form-control" value={deviceMatch} readOnly={updating} onChange={(e) => setDeviceMatch(e.target.value)}>
                                <option value={1} key={"dm_1"}>IS</option>
                                <option value={0} key={"dm_0"}>IS NOT</option>
                            </select>
                        </Col>
                        <Col md={4} sm={8}>
                            <select id="device" className="form-control" value={device} readOnly={updating} onChange={(e) => setDevice(e.target.value)}>
                                <option value="" key="dev_0">DON'T USE RULE</option>
                                <option value="desktop" key="dev_1">Desktop</option>
                                <option value="mobile" key="dev_2">Mobile</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="text-center pt-5">
                            <Card.Text>{formMessage}</Card.Text>
                            {!updating ? <Button title="Set Exclusion" className="info" /> : null}
                        </Col>
                    </Row>
                </Card.Body>
            </Col>
        </Row>
        </Form>
        <ConfirmDialog
            show={showConfirmDelete}
            title="Delete Exclusion" message="Are you sure you wish to delete this Exclusion?"
            onClose={() => { setShowConfirmDelete(false) }}
            onConfirm={deleteExclusion}
        />
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(SubpidExclusionTool), "Subpid Exclusion Tool"));