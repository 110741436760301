import React from 'react';
import { Row, Col, Card } from "react-bootstrap";

export default function CardHeader(props) {

    return (
        <Card.Header>
            <Row>
                <Col md={props.action?.path || props.action?.function ? 8 : 12} className={`${props.centerHeader ? 'text-center' : ''}`}>
                    <h4 className="title" id={`${props.id ?? ''}`}>{props.title}</h4>
                    {props.description && <p className="category">{props.description}</p> }
                </Col>
                { props.action?.path || props.action?.function ? 
                <Col md={4} className="text-right">
                    { props.action?.path ? <a href={props.action.path} className="btn btn-primary btn-sm"><span className="btn-label"><props.action.icon size={18} /> </span></a> : null }             
                    { props.action?.function ? <button className="btn btn-primary btn-sm" onClick={props.action?.function}><span className="btn-label"><props.action.icon size={18} /> </span></button> : null }             
                </Col> : null }
            </Row>
        </Card.Header>
    );
}
