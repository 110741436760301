import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import ImageUploading from 'react-images-uploading';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import CardHeader from '../../containers/CardHeader'

import { xml_img_list, xml_image_save } from '../../app/apiEndpoints';
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap';
import xmlAPICall from '../../app/apiCalls/xmlAPICall';
import InvalidPermissions from '../../containers/InvalidPermissions';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from '../../containers/Form/FormFooter';

const XMLImageList = (props) => {
    const [banners, setBanners] = useState([])
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    const [inputValue, setInputValue] = useState({ name: "", description: "" });
    const [errors, setErrors] = useState({ name: "", description: "" });
    const { name, description } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [saved, setSaved] = useState(false)

    const [images, setImages] = useState([]);
    const maxNumber = 1;

    useMemo(() => {
        const getBanners = async () => {
            const data = await xmlAPICall(xml_img_list, { 'requester': userID, 'company': companyID })
            if(data) {
                console.table(data)
                if(data.banners) setBanners(data.banners)
            }
        }
        getBanners()
    }, [userID, companyID])

    const onImageChange = (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);
    };

    const saveImage = async (e) => {
        e.preventDefault();
        if(saved) {
            setInputValue({ name: "", description: "" })
            setImages([])
            setFormMessage("")
            setHasErrors(false)
            setSaved(false)
            return;
        }

        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(name.length === 0) {
            formErrors.push("You must enter a Creative Name")
        }
        if(description.length === 0) {
            formErrors.push("You must enter a Description")
        }
        if(images.length === 0) {
            formErrors.push("You must upload an image")
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        //get image values
        let image = images[0]
        let fileData = image.data_url
        let fileName = image.file.name
        let fileType = image.file.type

        const data = await xmlAPICall(xml_image_save, {
            'requester': userID, 
            'company': companyID, 
            'name': name, 
            'description': description, 
            'fileName': fileName, 
            'fileData': fileData, 
            'fileType': fileType 
        })

        //if successful, delete from project object
        if(data.error) {
            setHasErrors(true)
            setFormMessage(data.error)
            return
        } 

        if(data.image?.image_id) {
            banners.push(data.image)
        }
        
        setFormMessage("Creative has been saved")
        setSaved(true)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };

    const handleError = (field, error) => {
        setErrors((prev) => ({
            ...prev,
            [field]: error,
        }));
    };

    if(!isSysadmin) return <InvalidPermissions />

    if(!banners) return <></>

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/xml/">XML</Breadcrumb.Item>
          <Breadcrumb.Item active>XML Image</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader  title="Upload Creative" description="Uploaded creatives for XML feeds" />
            <Card.Body>
            <Row>
                <Col md={8}>
                <Form onSubmit={saveImage} noCard={true} >
                    <Input
                        type="text"
                        value={name}
                        placeholder="Creative Name"
                        label="Creative Name"
                        name="name"
                        onChange={handleChange}
                        onError={handleError}
                        validate="required"
                        colSize="6"
                    />
                    <Input
                        type="text"
                        value={description}
                        placeholder="Description"
                        label="Description"
                        name="description"
                        onChange={handleChange}
                        onError={handleError}
                        validate="required"
                        colSize="6"
                    />
                    <FormFooter formButton={saved ? {title: "New Creative", class: "primary"} : {title: "Save Creative", class: "primary"}} center={true} formMessage={formMessage} hasErrors={hasErrors} />
                    </Form>
                </Col>
                <Col md={4}>
                    <br/>
                    <ImageUploading
                        value={images}
                        onChange={onImageChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                    >
                        {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <button
                            className='btn btn-primary mb-3'
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                            >
                            Upload Image
                            </button>
                            &nbsp;
                            {imageList.map((image, index) => (
                            <Row key={index}>
                            <Col md={4}>
                                <img src={image['data_url']} alt="" width="100" />
                            </Col>
                            <Col md={4}>
                                <div className="image-item__btn-wrapper">
                                <button onClick={() => onImageUpdate(index)} className='btn btn-secondary'><FaPencilAlt /></button>
                                <br />
                                <button onClick={() => onImageRemove(index)} className='btn btn-secondary'><FaTrash /></button>
                                </div>
                            </Col>
                            </Row>
                            ))}
                        </div>
                        )}
                    </ImageUploading>
                </Col>
            </Row>
            </Card.Body>
            </Card>
        <Card>
            <CardHeader title="Available Images" description="Previously uploaded images for XML feeds" />
            <Card.Body>
            <Row>
        {!banners || banners.length === 0 ?
            <>
                <Col md={12}>No images found. Please upload your first image</Col>
            </>
        :
                banners.sort((a, b) => b.image_id - a.image_id).map((file, b) => (
                    <Col md={3} className="text-center">
                    {//['jpg','jpeg','png','gif'].includes(file.file_type) ?
                    <>
                        <strong>{file.title}</strong><br/>
                        <img loading="lazy" src={`https://api.toplineadsportal.com/${file.image_url}`} width="150" alt={file.image_name} key={file.image_id} />
                        <p>{file.description}</p>
                        </>
                       // : <></>
                       }
                    {/*['mp4','ogg'].includes(file.file_type) ?
                        <video alt={file.file_name} key={file.image_id} controls>
                            <source src={(file.file_name)} type="video/mp4"></source>
                        </video>
                        : <></>*/}
                    {/* {['html'].includes(file.file_type) ?
                        (file.file_name.includes("toplineads") ?
                        <iframe loading="lazy" rel="sponsored noopener nofollow noindex" src={(file.file_name)} key={file.image_id} title={banner.banner_name} width={width > 0 ? width : 'auto'} height={height > 0 ? height : 'auto'} ></iframe> :
                        <iframe loading="lazy" rel="sponsored noopener nofollow noindex" srcDoc={Buffer.from(file.file, 'base64')} key={file.image_id} title={banner.banner_name} width={width > 0 ? width : 'auto'} height={height > 0 ? height : 'auto'} ></iframe>)
                        : <></>} */}
                    
                    </Col>
            ))
        }
                </Row>
                {banners && banners.length > 0 ? <p align="center"><a href="#Top" className='btn btn-primary btn-sm'>Back to Top</a></p> : <></>}
            </Card.Body>
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(XMLImageList), "XML Images"));
