import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useState, useMemo } from 'react'
import { useParams, withRouter } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";
import Select from "../../containers/Form/Select";
import InvalidPermissions from "../../containers/InvalidPermissions";

import usersAPICall from '../../app/apiCalls/usersAPICall'

import { SITE_NAME } from '../../app/globals'
import { user_details, user_save } from '../../app/apiEndpoints';
import ContentNotFound from '../../containers/ContentNotFound';

const UsersForm = (props) => {
    let { userID } = useParams();

    const [user, setUser] = useState({})
    const [inputValue, setInputValue] = useState({ company: 0, firstName: "", lastName: "", username: "", password: "", email: "", userGroup: 1, isActive: 1 });
    const [errors, setErrors] = useState({ company: "", firstName: "", lastName: "", username: "", password: "", email: "", userGroup: "", isActive: "" });
    const { company, firstName, lastName, username, password, email, userGroup, isActive } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [loading, setLoading] = useState(true)

    const requester = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isAdmin = useSelector((state) => state.user.isAdmin)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const newUser = !user.user_id
    const canEdit = isSysadmin

    useMemo(() => {
        const getDetails = async () => {
            if(parseInt(userID) > 0) {
                const data = await usersAPICall(user_details, {'userID': userID, 'requester': requester, 'company': companyID })
                if(data.user?.user_id) {
                    const values = {company: data.user.company_id, firstName: data.user.first_name, lastName: data.user.last_name, username: data.user.username, password: "", email: data.user.email, userGroup: data.user.user_group_id, isActive: data.user.isactive}
                    setUser(data.user)
                    setInputValue(values)
                } else if(data.error) {
                    setFormMessage(data.error)
                    setHasErrors(true)
                }
                if(data.found && data.found === "0") {
                    setUser({ missing: true })
                }
                setLoading(false)
            }
        }
        if(parseInt(userID) > 0 && (user === undefined || user === null || !user.user_id) && loading) {
            getDetails()
        } else if(userID === "add") {
            setLoading(false)
        }
    }, [userID, requester, companyID, user, loading])

    const saveUser = async (e) => {
        e.preventDefault()
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await usersAPICall(user_save, {
            'userID': user.user_id ? user.user_id : 0, 
            'requester': requester, 
            'first_name': firstName, 
            'last_name': lastName, 
            'username': username, 
            'email': email, 
            'company_id': company, 
            'user_group_id': userGroup, 
            'isactive': isActive, 
            'password': password 
        })

        //if successful, delete from project object
        if(data.error) {
            setFormMessage(data.error)
            setHasErrors(true)
            return
        } 

        if(data.user?.user_id) userID = data.user.user_id
        setUser(data.user)
        
        setFormMessage("User has been saved")
        window.history.replaceState(null, SITE_NAME, `/users/${userID}`);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
        setErrors((prev) => ({
            ...prev,
            [field]: error,
        }));
    };

    if(!(isSysadmin || isAdmin)) 
        return <InvalidPermissions />

    if(user.missing)
        return <ContentNotFound contentType="User" />

    return (
        <>
        <Form title={!newUser ? (isSysadmin ? "Edit User" : "View User") : "Add User"}  description="" onSubmit={saveUser} >
            { !loading ?
            <>
            {canEdit ?
            <Select
                value={company}
                label="Company"
                name="company"
                formField="companies"
                useDefault={true}
                onChange={handleChange}
                onError={handleError}
                validate="numeric|required"
                colSize="6"
                readOnly={!canEdit}
            />
            : <></>}

            <Input
                type="text"
                value={firstName}
                placeholder="First Name"
                label="First Name"
                name="firstName"
                onChange={handleChange}
                onError={handleError}
                validate="name|required"
                colSize="6"
                readOnly={!canEdit}
            />

            <Input
                type="text"
                value={lastName}
                placeholder="Last Name"
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                onError={handleError}
                validate="name|required"
                colSize="6"
                readOnly={!canEdit}
            />

            <Input
                type="text"
                value={email}
                placeholder="Email"
                label="Email"
                name="email"
                onChange={handleChange}
                onError={handleError}
                validate="email|required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Input
                type="password"
                value={password}
                placeholder="Password"
                label="Password"
                name="password"
                onChange={handleChange}
                onError={handleError}
                validate={newUser ? "required" : ""}
                colSize="6"
                readOnly={!canEdit}
            />
            <Select
                value={userGroup}
                label="User Group"
                name="userGroup"
                formField="userGroups"
                useDefault={true}
                onChange={handleChange}
                onError={handleError}
                validate="numeric"
                colSize="3"
                readOnly={!canEdit}
            />
            <Select
                value={isActive}
                label="Active?"
                name="isActive"
                formField="yesno"
                onChange={handleChange}
                onError={handleError}
                validate="numeric"
                colSize="3"
                readOnly={!canEdit}
            />
            {canEdit && 
            <FormFooter formButton={{title: "Save User", class: "primary"}} center={true} formMessage={formMessage} hasErrors={hasErrors} /> }
            </>
            : <p>Loading form data...</p> }
        </Form>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(withRouter(UsersForm)), "User Form"));