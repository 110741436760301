import React from 'react';

import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import DataTable from '../../containers/DataTable'

import usersAPICall from '../../app/apiCalls/usersAPICall'
import { users_list } from '../../app/apiEndpoints'

const UsersTable = (props) => {
    const [data, setData] = useState([])

    const tableID = "users"
    const tableFields = ["Name", "User Name", "Email", "Company", "User Group", "Is Active?"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentClientID)
    const filter = parseInt(props.filter) > 0 ? props.filter : 0

    useMemo(() => {
        const getData = async () => {
            
            const data = await usersAPICall(users_list, {'requester': userID, 'company': companyID, 'filter': filter })
            setData(data.users)
        }
        getData()
    }, [userID, companyID, filter])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.user_id, 'values': [
                (props.showDetailsOnClick ? `${row.first_name} ${row.last_name}` : `<a href="/users/${row.user_id}">${row.first_name} ${row.last_name}</a>`),
                row.username,
                row.email,
                row.company_name,
                row.group_name,
                parseInt(row.isactive) === 1 ? "Active" : "Inactive"
            ]});
        });
        return rows
    }

    return (
        <>
        <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} currentRowID={rowID} />
        </>
    )
}

export default connect(state => ({...state}))(UsersTable)