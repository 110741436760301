import React from 'react';
import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import projectAPICall from '../../app/apiCalls/projectAPICall'

import { FaEdit } from "react-icons/fa";
import CardHeader from '../../containers/CardHeader';
import CardSectionTitle from '../../containers/CardSectionTitle';
import ProjectUpdate from './ProjectUpdate';
import ProjectUpdateForm from './ProjectUpdateForm';
import Button from "../../containers/Form/Button";

import { doProjectUpdate } from '../../app/store/actions/projectActions'
import { project_del_update, project_details, project_mark_complete } from '../../app/apiEndpoints';
import { Card, Row, Col, Accordion } from 'react-bootstrap';

const ProjectDetails = (props) => {
    const [project, setProject] = useState({})
    const projectID = useSelector((state) => state.currentProjectID)
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const canEditUpdates = isSysadmin && parseInt(project?.paid) === 0

    const headerAction = canEditUpdates ? {'path': `/projects/${projectID}`, 'icon':FaEdit} : {}

    useMemo(() => {
        const getProject = async () => {
            if(projectID > 0) {
                const data = await projectAPICall(project_details, {'projectID': projectID, 'requester': userID, 'company': companyID })
                setProject(data.project)
            }
        }
        getProject()
    }, [projectID, userID, companyID])

    const projectStatus = () => {
        let status = '<span class="text-success">Active</span>';
        if(project.status === 2 && parseInt(project.invoice_id) === 0) status = '<span class="text-warning">Complete; Pending Invoice</span>';
        else if(parseInt(project.invoice_id) > 0) status = `<a href="/invoices/${project.invoice_id}" title="View Invoice" target="_blank">${((parseInt(project.paid) === 2) ? 'Paid' : 'Invoiced')}</a>`;
        return status
    }

    const markComplete = async () => {
        console.log('complete');
        
        //call API to delete
        const data = await projectAPICall(project_mark_complete, {'projectID': projectID, 'requester': userID })

        //if successful, delete from project object
        if(!data.success || data.error) {
            alert(data.error)
            return
        } 
        project.status = 2
        props.doProjectUpdate(project)
    }

    const deleteUpdate = async (updateID) => {
        //call API to delete
        const data = await projectAPICall(project_del_update, {'updateID': updateID, 'projectID': projectID, 'requester': userID, 'company': companyID })

        //if successful, delete from project object
        if(!data.success || data.error) {
            alert(data.error)
            return
        } 
        const updateIndex = project.updates.findIndex(update => (update.update_id === updateID));
        project.updates.splice(updateIndex,1);
        project.hours = data.hours
        props.doProjectUpdate(project)
    }

    const saveUpdate = async (update, hours) => {
        project.updates.push(update)
        project.hours = hours
        props.doProjectUpdate(project)
    }

    if(!projectID) return <></>

    return (
        <Card>
            <CardHeader title={project.project_name} description={project.company_name} action={headerAction} />
            <Card.Body>
                <Accordion>
                    <Card>
                        <CardSectionTitle title="About" />
                        <Card.Body>{project.description}</Card.Body>
                    </Card>
                    <Card>
                        <CardSectionTitle title="Status" />
                        <Card.Body>
                            <Row>
                                <Col md={8} dangerouslySetInnerHTML={{ __html: projectStatus() }} />
                                <Col md={4}>{(isSysadmin && parseInt(project.status) === 1 && parseInt(project.invoice_id) === 0 ? <Button title="Mark Completed" className="info btn-sm" onClick={markComplete} /> : '')}</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <CardSectionTitle title="Updates" />
                        <Card.Body>
                            {project.updates?.length > 0 ?
                            project.updates.map((update,u) => (
                                <ProjectUpdate update={update} canEdit={canEditUpdates} onClick={deleteUpdate} key={u} />
                            )) : null}
                        </Card.Body>
                    </Card>
                    {isSysadmin && parseInt(project.paid) === 0 ?
                    <Card>
                        <ProjectUpdateForm curProjectID={projectID} onSubmit={saveUpdate} />
                    </Card> : ''}
                </Accordion>
            </Card.Body>
        </Card>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        doProjectUpdate: (project) => { 
            dispatch(doProjectUpdate(project))
        }
    }
  }

export default connect(state => ({...state}), mapDispatchToProps)(ProjectDetails)