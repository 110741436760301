import React from 'react';
import { useState, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import Moment from 'moment';

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_cam_model_save, tool_cam_model_hide } from "../../../app/apiEndpoints"
import { Card, FormGroup } from 'react-bootstrap'
import Form from '../../../containers/Form/Form';
import Button from '../../../containers/Form/Button';

const CamModelToolDetails = (props) => {
    const [model, setModel] = useState({})
    const userID = useSelector((state) => state.user.id)

    const [customURL, setCustomURL] = useState('')
    const [processing, setProcessing] = useState(false)

    useMemo(() => {
        setModel(props.model)
        if(parseInt(props.model.modified) === 1 && props.model.img_url.length > 0) {
            setCustomURL(props.model.img_url)
        } else {
            setCustomURL('')
        }
    }, [props.model])

    const convertDate = (date) => {
        return Moment(date).format('MM/DD/YYYY')
    }

    const updateModel = async () => {
        setProcessing(true)
        const data = await toolsAPICall(tool_cam_model_save, {'modelid': model.id, 'requester': userID, 'imgurl': customURL })
        console.log(data)

        //if successful, delete from project object
        if(data.error) {
            alert(data.error)
            return
        } 

        if(data.model)
            props.returnModel(data.model)
        setProcessing(false)
    }

    const hideModel = async () => {
        setProcessing(true)
        const data = await toolsAPICall(tool_cam_model_hide, {'modelid': model.id, 'requester': userID, 'hide': 1 })
        console.log(data)

        //if successful, delete from project object
        if(data.error) {
            alert(data.error)
            return
        }
        //model.hide = 1
        if(data.model)
            props.returnModel(data.model)
        setProcessing(false)
    }

    const showModel = async () => {
        setProcessing(true)
        const data = await toolsAPICall(tool_cam_model_hide, {'modelid': model.id, 'requester': userID, 'hide': 0 })
        console.log(data)

        //if successful, delete from project object
        if(data.error) {
            alert(data.error)
            return
        }
        //model.hide = 0
        if(data.model)
            props.returnModel(data.model)
        setProcessing(false)
    }

    if(!model) return <></>

    return (
        <>
        <Card.Header>
            <Card.Title>{model.model}</Card.Title>
            <Card.Subtitle>Gender: {model.gender}</Card.Subtitle>
            <Card.Subtitle>PPM: {model.ppm}</Card.Subtitle>
            <Card.Subtitle>Data Last Pulled: {convertDate(model.last_updated)}</Card.Subtitle>
            <Card.Subtitle>Status: {parseInt(model.hide) === 1 ? "Hidden" : "Visible"}</Card.Subtitle>
        </Card.Header>
        <Card.Body>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Default Picture
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <img src={model.default_img_url} className="image" alt={`Default for ${model.model}`} />
                    <Card.Subtitle>{model.default_img_url}</Card.Subtitle>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Custom Picture
                    </Card.Title>
                </Card.Header>
                <Card.Body id="custom-image">
                {model.modified === "1" ?
                    <>
                    <img src={model.img_url} className="image" alt={`Custom for ${model.model}`} />
                    <Card.Subtitle>Set on {convertDate(model.modified_date)}</Card.Subtitle>
                    </>
                : <p>No image set; using default image</p> }
                </Card.Body>
            </Card>
            <Form title="Set Custom Image">
                <FormGroup>
                    <input type="text" id="imgurl" name="imgurl" value={customURL} onChange={(e) => setCustomURL(e.target.value)} placeholder="Image URL" className="form-control" />
                </FormGroup>
                <Card.Footer>
                    {!processing && <Button title="Update Model" className="info" onClick={updateModel} /> }
                </Card.Footer>
            </Form>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Set Hidden
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Footer>
                        Status: {parseInt(model.hide) === 1 ? "Hidden" : "Visible"}
                        {parseInt(model.hide) === 1 && !processing && <Button title="Show Model" className="info" onClick={showModel} /> }
                        {parseInt(model.hide) !== 1 && !processing && <Button title="Hide Model" className="info" onClick={hideModel} />}
                    </Card.Footer>
                </Card.Body>
            </Card>
        </Card.Body>
        </>
    )
}

export default connect(state => ({...state}))(CamModelToolDetails)