import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import CardHeader from '../../../containers/CardHeader'
import Form from '../../../containers/Form/Form'
import Button from '../../../containers/Form/Button'

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_tj_bid } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import { Card, Row, Col, InputGroup, FormControl } from 'react-bootstrap'
import { tableTypes } from '../../../app/settings'

const TJBulkBidTool = (props) => {
    const toolID = 4
    const [tool, setTool] = useState({})
    const [bid, setBid] = useState('')
    const [source, setSource] = useState(1)
    const [campaignids, setCampaignids] = useState('')
    const [formMessage, setFormMessage] = useState('')
    const [updating, setUpdating] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [campaigns, setCampaigns] = useState([])
    const [curCampaign, setCurCampaign] = useState(-1)

    const tableID = "result"
    const tableFields = ["Campaign ID", "Campaign Name", "Old Bid", "Status"]

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const performUpdate = async () => {
            if(campaigns[curCampaign]) {
                const item = campaigns[curCampaign]
                const data = await toolsAPICall(tool_tj_bid, { 'campaignid': item.id, 'bid': bid, 'source': source })
                if(data.error) {
                    setFormMessage(data.error)
                } 
                else if(data.campaign) {
                    campaigns[curCampaign] = data.campaign
                    setCampaigns(campaigns)
                }
                setCurCampaign(curCampaign+1)
            } else {
                setFormMessage("Campaign Updates complete")
            }
        }
        
        if(curCampaign >= 0) {
            performUpdate()
        }
    }, [campaigns, curCampaign, bid, source])

    const tableData = () => {
        if(campaigns.length === 0) {
            return [];
        } 
        console.log('data', campaigns)
        let rows = [];
        campaigns.forEach(row => {
            rows.push({'id': row.id, 'values': [
                row.id,
                row.name,
                row.bid,
                row.status || "Pending"
            ]});
        });
        return rows
    }

    const updateCampaigns = (e) => {
        e.preventDefault()
        setFormMessage("")

        if(!bid || isNaN(parseFloat(bid)) || parseFloat(bid) < 0) {
            setFormMessage("Please enter a New Bid")
            return
        }
        if(!campaignids || campaignids?.length === 0) {
            setFormMessage("Please enter at least one Campaign ID")
            return
        }

        // split rows and remove duplicates
        let ids = campaignids.split("\n")
        ids = ids.filter(function(item, pos) {
            return ids.indexOf(item) === pos;
        })

        // convert to objects
        const newCampaigns = ids.reduce((camps, cId) => {
            if(!camps.includes({'id': cId})) camps.push({'id': cId})
            return camps
        }, [])

        setUpdating(true)
        setCampaigns(newCampaigns)
        setCurCampaign(0)
    }


    if(!tool) return <></>

    return (
        <>
        <Row>
            <Col md={6}>
                <Form title={tool.tool_name} description={tool.description} onSubmit={updateCampaigns}>  
                <Card.Body>
                    <Row>
                        <Col md={4} className="control-label col-form-label">New Bid</Col>
                        <Col md={3}>
                            <InputGroup>
                                <InputGroup.Text>$</InputGroup.Text>
                                <FormControl aria-label="Bid" type="text" name="bid" id="bid" onChange={(e) => setBid(e.target.value)} readOnly={updating} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control-label col-form-label">Source</Col>
                        <Col md={3}>
                            <select id="source" name="source" className="form-control" value={source} readOnly={updating} onChange={(e) => setSource(e.target.value)}>
                                <option value="1">Banners</option>
                                <option value="2">Popups</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control-label col-form-label">Campaign IDs</Col>
                        <Col md={5}>
                            <textarea className="form-control" id="campaignids" name="campaignids" readOnly={updating} style={{height: 200 }} value={campaignids} onChange={(e) => setCampaignids(e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Card.Text>{formMessage}</Card.Text>
                            {!updating ? <Button title="Update Campaigns" className="info" /> : null}
                        </Col>
                    </Row>
                </Card.Body>
                </Form>
            </Col>
            <Col md={6}>
                <Card>
                    <CardHeader title="Update History" description="Campaigns will appear here as they are updated" />
                    <DataTable hover={false} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None} />
                </Card>
            </Col>
        </Row>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(TJBulkBidTool), "Traffic Junky Bulk Bid Updater"));