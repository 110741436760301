import React from 'react';

export default function TopMenuLink(props) {

    return (
        <li className="nav-item">
            <a className="nav-link" href={props.linkPath}>
                <span className="no-icon">{props.linkName}</span>
            </a>
        </li>
    );
}
