import reportAPICall from '../../app/apiCalls/reportAPICall'
import { report_data } from "../../app/apiEndpoints"

const generateReport = async (report, startDate, endDate, source) => {

    let reportData = []
    let csv = {file: "", data: ""}

    
    const tableFields = () => {
        if(reportData.length === 0) return []
        const row = reportData[0]
        const fields = Object.keys(row).map((field) => field)
        return fields
    }

    const csvData = () => {
        if(reportData.length === 0) return []
        const dataRows = []
        dataRows.push(tableFields())
        reportData.forEach((row, i) => {
            const values = []
            Object.keys(row).forEach((field) => {
                let value = row[field];
    
                // Replace commas and quotes with HTML entities
                if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
                    value = value.replace(/,/g, ';');
                }
    
                values.push(value);
            });
            dataRows.push(values);
        });
        return dataRows
    }
    

    const csvFileName = () => {
        if(report.report_name.length === 0) return "Report"
        return `${report.report_name.replaceAll(" ", "_")}.csv`
    }

    if(report.report_id > 0 && (parseInt(report.has_date_range) === 0 || (startDate.length > 0 && endDate.length > 0))) {
        const data = await reportAPICall(report_data, {
            'reportID': report.report_id, 
            'startDate': startDate, 
            'endDate': endDate, 
            'source': source 
        })
        reportData = (data.data)
        csv = {file: csvFileName(), data: csvData()}
    }


    return csv
}

export default generateReport;