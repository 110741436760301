import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useState, useMemo } from 'react'
import { useParams, withRouter } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";

import bannerAPICall from '../../app/apiCalls/bannerAPICall'

import { SITE_NAME } from '../../app/globals'
import { FaEye } from "react-icons/fa";
import { banner_site_details, banner_site_save } from '../../app/apiEndpoints';
import InvalidPermissions from '../../containers/InvalidPermissions';
import ContentNotFound from '../../containers/ContentNotFound';
import { Breadcrumb } from 'react-bootstrap';

const BannerSiteForm = (props) => {
    let { siteURI } = useParams();

    const [site, setSite] = useState({})
    const [inputValue, setInputValue] = useState({ name: "", url: "" });
    const [errors, setErrors] = useState({ name: "", url: "" });
    const { name, url } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [loading, setLoading] = useState(true)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const canEdit = isSysadmin
    const newSite = !site.site_id

    const headerAction = !newSite ? {'path': `/banners/${siteURI}`, 'icon':FaEye} : {}

    useMemo(() => {
        const getBanner = async () => {
            if(siteURI && siteURI.length > 0) {
                const data = await bannerAPICall(banner_site_details, {'siteURI': siteURI, 'requester': userID, 'company': companyID })

                if(data.site?.site_id) {
                    const values = { name: data.site.site_name, url: data.site.site_url }
                    setInputValue(values)
                    setSite(data.site)
                } else if(data.error) {
                    setFormMessage(data.error)
                    setHasErrors(true)
                }
                if(data.found && data.found === "0") {
                    setSite({ missing: true })
                }
                setLoading(false)
            }
        }
        if(siteURI && siteURI.length > 0 && (site === undefined || site === null || !site.site_id) && loading) {
            getBanner()
        } else if(!siteURI || siteURI === "add" || siteURI === "") {
            setLoading(false)
        }
    }, [siteURI, userID, companyID, site, loading])

    const saveSite = async (e) => {
        e.preventDefault()
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await bannerAPICall(banner_site_save, {
            'siteID': site.site_id ?? 0, 
            'requester': userID, 
            'name': name, 
            'url': url
        })

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            setHasErrors(true)
            return
        } 

        if(data.site?.site_uri) siteURI = data.site.site_uri
        setSite(data.site)
        
        console.log(props)
        setFormMessage("Site has been saved")
        window.history.replaceState(null, SITE_NAME, `/banners/${siteURI}/edit`);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
          setErrors((prev) => ({
              ...prev,
              [field]: error,
          }));
    };

    if(siteURI === "add" && !isSysadmin)
        return <InvalidPermissions />

    if(site.missing)
        return <ContentNotFound contentType="Banner Site" />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/banners/">Banners</Breadcrumb.Item>
          <Breadcrumb.Item active>{!newSite ? "Edit Site" : "Add Site"}</Breadcrumb.Item>
        </Breadcrumb>
        <Form title={!newSite ? "Edit Site" : "Add Site"}  description="" onSubmit={saveSite} headerAction={headerAction} >
            { !loading ?
            <>
            <Input
                type="text"
                value={name}
                placeholder="Site Name"
                label="Site Name"
                name="name"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={url}
                placeholder="Site URL"
                label="Site URL"
                name="url"
                onChange={handleChange}
                onError={handleError}
                validate="url"
                colSize="6"
                readOnly={!canEdit}
            />
            {canEdit && 
            <FormFooter 
                formButtons={newSite ? [{title: "Save Site", class: "primary"}] : [{title: "Save Site", class: "primary"}]} 
                center={true} 
                formMessage={formMessage} 
                hasErrors={hasErrors} /> }
            </>
            : <p>Loading form data...</p> }
        </Form>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(withRouter(BannerSiteForm)), "Site Form"));
