import React from 'react';
import { Card } from "react-bootstrap";

export default function CardSectionTitle(props) {

    return (
            <Card.Header>
                <Card.Title>
                        {props.title}
                </Card.Title>
            </Card.Header>
    );
}
