import React from 'react'
import './index.css'
import App from './App'
import Store from './app/store/store'
import { Provider } from 'react-redux'

import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>
);
