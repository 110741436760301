import React from 'react';

import { connect, useSelector, useDispatch } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import CardHeader from '../../containers/CardHeader'

import { Card, Col, Row } from 'react-bootstrap'
import InvalidPermissions from '../../containers/InvalidPermissions'
import adminAPICall from '../../app/apiCalls/adminAPICall'
import { admin_stats } from '../../app/apiEndpoints'
import { useMemo, useState } from 'react'
import Select from '../../containers/Form/Select'
import Button from '../../containers/Form/Button'
import { loginAsClient, logoutAsClient } from '../../app/store/actions/loginActions'

const AdminSection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const isViewingAsClient = useSelector((state) => state.isViewingAsClient)
    const userID = useSelector((state) => state.user.id)
    const company = useSelector((state) => state.user.company)

    const [companyID, setCompanyID] = useState(0)
    const [companyName, setCompanyName] = useState("")

    const [stats, setStats] = useState({})
    const dispatch = useDispatch();

    useMemo(() => {
        const getData = async () => {
            setCompanyID(company)
            const data = await adminAPICall(admin_stats, {'requester': userID, 'company': company.id ?? 0 })
            if(data.stats?.length > 0) setStats(data.stats)
        }
        if(company !== undefined)
            getData()
    }, [userID, company])

    const doLogin = (e) => {
        e.preventDefault()
        if(companyID > 0)
            dispatch(loginAsClient(companyID, companyName))
    }

    const doLogout = (e) => {
        e.preventDefault()
        dispatch(logoutAsClient())
    }

    const selectClient = (e) => {
        setCompanyID(e.target.value)
        setCompanyName(e.target.options[e.target.selectedIndex].text)

    }
    
        
    if(!(isSysadmin || isViewingAsClient))
        return <InvalidPermissions></InvalidPermissions>

    return (
        <>
        <Card>
            <CardHeader title="Admin Stats" description="" />
            <br></br>
            <Row>
            { stats.length > 0 ?
                    stats.map((stat,s) => (
                        <Col md={4} key={stat.api_name}>
                                <CardHeader title={stat.api_name} description={`Data last pulled: ${stat.lastUpdate}`} />
                                <p></p>
                        </Col>
                    )) : '' 
                }
            </Row>
        </Card>
        {isViewingAsClient ? 
        <Card>
            <CardHeader title="Return to Admin" description="Return to your default login" />
            <Card.Body className="card-body">
                <p>Currently logged in as {company.company_name}</p>
            <Button title="Return Account" className="btn btn-primary" onClick={doLogout} />
            </Card.Body>
        </Card>
        :
        <Card>
            <CardHeader title="Login as Client" description="Select a client to simulate their login and see what they see" />
            <Card.Body className="card-body">
            <Select
                value={companyID}
                label="Company"
                name="companyID"
                formField="companies"
                useDefault={false}
                onChange={selectClient}
                validate="numeric|required"
                colSize="6"
            />
            <Button title="Login" className="btn btn-primary" onClick={doLogin} />
            </Card.Body>
        </Card>
        }
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginAsClient: (companyID, companyName) => { 
            dispatch(loginAsClient(companyID, companyName))
        },
        logoutAsClient: () => {
            dispatch(logoutAsClient)
        }
    }
  }


export default connect(state => ({...state}), mapDispatchToProps)(pageWithSideMenu(pageContent(AdminSection), "Admin"));
