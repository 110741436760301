import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import CardHeader from '../../containers/CardHeader'

import { xml_save, xml_details } from '../../app/apiEndpoints';
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap';
import xmlAPICall from '../../app/apiCalls/xmlAPICall';
import InvalidPermissions from '../../containers/InvalidPermissions';
//import { FaTrash } from 'react-icons/fa';
import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from '../../containers/Form/FormFooter';

const XMLForm = (props) => {
    let { xmlID } = useParams();
    const history = useHistory();

    //const [xml, setXML] = useState({})
    const [banners, setBanners] = useState([])
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    const [inputValue, setInputValue] = useState({ name: "", description: "" });
    const [errors, setErrors] = useState({ name: "", description: "" });
    const { name, description } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)

    const [images, setImages] = useState([]);

    useMemo(() => {
        const getBanners = async () => {
            const data = await xmlAPICall(xml_details, { 'xmlID': xmlID, 'requester': userID, 'company': companyID })
            if(data) {
                if(data.banners) setBanners(data.banners)
                if(data.xml) {
                    const xmlValues = { name: data.xml.xml_name, description: data.xml.description }
                    setInputValue(xmlValues)
                    //setXML(data.xml)
                    setImages(data.images)
                }
            }
        }
        getBanners()
    }, [xmlID, userID, companyID])

    const saveXML = async (e) => {
        e.preventDefault();
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(!name || name.length === 0) {
            formErrors.push("You must enter a Feed Name")
        }
        if(!images || images.length === 0) {
            formErrors.push("You must select at least one image")
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        //get image values


        const data = await xmlAPICall(xml_save, {
            'xmlID': xmlID,
            'requester': userID, 
            'company': companyID, 
            'name': name, 
            'description': description ?? "", 
            'images': images
        })

        //if successful, delete from project object
        if(data.error) {
            setHasErrors(true)
            setFormMessage(data.error)
            return
        } 

        
        setFormMessage("XML Feed has been saved")
        if(data.xmlid) {
        history.push(`/xml/feeds/${data.xmlid}`);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };

    const handleError = (field, error) => {
        setErrors((prev) => ({
            ...prev,
            [field]: error,
        }));
    };

    const onImageClick = (e) => {

        var array = [...images]; // make a separate copy of the array
        var index = array.indexOf(e.target.id)
        if (index !== -1) {
            array.splice(index, 1);
            setImages(array);
        } else {
            array.push(e.target.id)
            setImages(array)
        }
        console.table(array)
        console.table(banners)
    }

    /*
    const onImageRemove = (image) => {
        
    };
    */

    const imageUrl = (index) => {
        let banner = banners.find(banner => banner.image_id === index)
        console.log(banner)
        return banner.image_url ?? ""
    }

    if(!isSysadmin) return <InvalidPermissions />

    if(!banners) return <></>

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/xml/">XML</Breadcrumb.Item>
          <Breadcrumb.Item href="/xml/feeds/">XML Feeds</Breadcrumb.Item>
          <Breadcrumb.Item active>{xmlID > 0 ? "Edit XML Feed" : "Add XML Feed"}</Breadcrumb.Item>
        </Breadcrumb>
        <Form title={xmlID > 0 ? "Edit XML Feed" : "Add XML Feed"} description="Configure XML feed" onSubmit={saveXML} >
            <Card.Body>
            <Row>
                <Col md={8}>
                    <Input
                        type="text"
                        value={name}
                        placeholder="XML Feed Name"
                        label="XML Feed Name"
                        name="name"
                        onChange={handleChange}
                        onError={handleError}
                        validate="required"
                        colSize="6"
                    />
                    <Input
                        type="text"
                        value={description}
                        placeholder="Description"
                        label="Description (optional)"
                        name="description"
                        onChange={handleChange}
                        onError={handleError}
                        validate=""
                        colSize="6"
                    />
                    {xmlID && xmlID > 0 ?
                    <Input
                        type="text"
                        value={`https://api.toplineadsportal.com/xml/feeds/?${xmlID}&redir=`}
                        placeholder=""
                        label="XML URL"
                        name="url"
                        colSize="8"
                        readOnly={true}
                    /> : <></>}
                    <FormFooter formButton={{title: "Save XML Feed", class: "primary"}} center={true} formMessage={formMessage} hasErrors={hasErrors} />

                </Col>
                <Col md={4}>
                <strong>Selected Images</strong>
                <Row>
                    {images.map((fileid, index) => (
                    <> { 
                    <Col md={3} key={index} className="m-2">
                        <img src={`https://api.toplineadsportal.com/${imageUrl(fileid)}`} alt="" width="100" />
                    </Col> }
                    </>
                    ))}
                    </Row>
                </Col>
            </Row>
            </Card.Body>
        </Form>
        <Card>
            <CardHeader title="Available Images" description="Select images to include in XML feed" />
            <Card.Body>
            <Row>
        {!banners || banners.length === 0 ?
            <>
                <Col md={12}>No images found. Please upload your first image</Col>
            </>
        :
                banners.sort((a, b) => b.image_id - a.image_id).map((file, b) => (
                    <Col md={3} className="text-center">
                    {//['jpg','jpeg','png','gif'].includes(file.file_type) ?
                    <>
                        <strong>{file.title}</strong><br/>
                        <img loading="lazy" src={`https://api.toplineadsportal.com/${file.image_url}`} className={images.indexOf(file.image_id) !== -1 ? "hover selected_img" : "hover"} width="150" alt={file.image_name} onClick={onImageClick} id={file.image_id} key={file.image_id} />
                        <p>{file.description}</p>
                        </>
                       // : <></>
                       }
                    {/*['mp4','ogg'].includes(file.file_type) ?
                        <video alt={file.file_name} key={file.image_id} controls>
                            <source src={(file.file_name)} type="video/mp4"></source>
                        </video>
                        : <></>*/}
                    {/* {['html'].includes(file.file_type) ?
                        (file.file_name.includes("toplineads") ?
                        <iframe loading="lazy" rel="sponsored noopener nofollow noindex" src={(file.file_name)} key={file.image_id} title={banner.banner_name} width={width > 0 ? width : 'auto'} height={height > 0 ? height : 'auto'} ></iframe> :
                        <iframe loading="lazy" rel="sponsored noopener nofollow noindex" srcDoc={Buffer.from(file.file, 'base64')} key={file.image_id} title={banner.banner_name} width={width > 0 ? width : 'auto'} height={height > 0 ? height : 'auto'} ></iframe>)
                        : <></>} */}
                    
                    </Col>
            ))
        }
                </Row>
                {banners && banners.length > 0 ? <p align="center"><a href="#Top" className='btn btn-primary btn-sm'>Back to Top</a></p> : <></>}
            </Card.Body>
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(XMLForm), "XML Feed"));
