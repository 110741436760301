import React from 'react';

import { connect, useSelector, useDispatch } from 'react-redux'
import { useState, useMemo } from 'react'
import { useParams, withRouter } from 'react-router-dom';
import moment from 'moment';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'
import CardHeader from '../../containers/CardHeader';
import ConfirmDialog from '../../containers/ConfirmDialog';

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";
import Select from "../../containers/Form/Select";
import Button from "../../containers/Form/Button";

import invoiceAPICall from '../../app/apiCalls/invoiceAPICall';
import { invoice_add_project, invoice_delete, invoice_details, invoice_rem_project, invoice_save, invoice_form_fields } from "../../app/apiEndpoints"

import { selectInvoice } from '../../app/store/actions/invoiceActions'

import { SITE_NAME } from '../../app/globals'
import { FaPrint } from "react-icons/fa";
import InvalidPermissions from '../../containers/InvalidPermissions';
import { Card, Row, Col, Table } from 'react-bootstrap';

const InvoiceForm = (props) => {
    let { invoiceID } = useParams();

    const [invoice, setInvoice] = useState({})
    const [inputValue, setInputValue] = useState({ company: 0, date: new Date(), hours: 0.00, billed: 0.00 });
    const [errors, setErrors] = useState({ company: "", date: "", hours: "", billed: "" });
    const { company, date, hours, billed } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [loading, setLoading] = useState(true)
    const [newProject, setNewProject] = useState(0)

    const [availableProjects, setAvailableProjects] = useState([])

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    const newInvoice = !invoice.invoice_id
    const canEdit = (newInvoice || parseInt(invoice?.status) === 0) && isSysadmin
    const headerAction = canEdit ? {'path': `/invoices/print/${invoiceID}`, 'icon':FaPrint} : {}

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const dispatch = useDispatch();

    useMemo(() => {
        const getInvoice = async () => {
            if(parseInt(invoiceID) > 0) {
                const data = await invoiceAPICall(invoice_details, {'invoiceID': invoiceID, 'requester': userID, 'company': companyID })
                if(data.invoice?.invoice_id) {
                    const values = { company: data.invoice.company_id, date: new Date(data.invoice.invoice_date), hours: data.invoice.total_hours, billed: data.invoice.total_billed }
                    setInvoice(data.invoice)
                    setInputValue(values)
                } else if(data.error) {
                    setFormMessage(data.error)
                    setHasErrors(true)
                }
                setLoading(false)
            }
        }
        if(parseInt(invoiceID) > 0 && (invoice === undefined || invoice === null || !invoice.invoice_id) && loading) {
            getInvoice()
        } else if(invoiceID === "add") {
            setLoading(false)
        }
    }, [invoiceID, userID, companyID, invoice, loading])

    useMemo(() => {
        const getFields = async () => {
            const data = await invoiceAPICall(invoice_form_fields, {'company': company})
            console.log(data.companies)
            if(data?.projects) setAvailableProjects(data.projects)
        }
        console.log("load projects")
        getFields()
    }, [company])

    const saveInvoice = async (e) => {
        e.preventDefault()
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')
        
        const data = await invoiceAPICall(invoice_save, {
            'invoiceID': invoice.invoice_id ? invoice.invoice_id : 0, 
            'requester': userID, 
            'company': company, 
            'date': moment(date).format('MM/DD/YYYY') 
        })

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            setHasErrors(true)
            return
        } 

        if(data.invoice?.invoice_id) invoiceID = data.invoice.invoice_id
        setInvoice(data.invoice)
        
        console.log(props)
        setFormMessage("Invoice has been saved")
        window.history.replaceState(null, SITE_NAME, `/invoices/${invoiceID}`);
        dispatch(selectInvoice(invoiceID))
    }

    const confirmDeleteInvoice = (e) => {
        e.preventDefault()
        setShowConfirmDelete(true)
    }

    const deleteInvoice = async () => {
        const data = await invoiceAPICall(invoice_delete, {
            'invoiceID': invoice.invoice_id, 
            'requester': userID
        })

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            return
        } 

        console.log('delete project')
        setInvoice({})
        
        props.history.push('/invoices')
        dispatch(selectInvoice(0))
    }

    const addProject = async () => {
        if(newProject === 0) return;
        //call API to delete
        const data = await invoiceAPICall(invoice_add_project, {
            'invoiceID': invoice.invoice_id, 
            'projectID': newProject, 
            'requester': userID, 
            'company': companyID 
        })

        //if successful, delete from project object
        if(!data.success || data.error) {
            alert(data.error)
            return
        } 

        if(data.invoice) {
            const projectIndex = availableProjects.findIndex(project => (project.project_id === newProject));
            availableProjects.splice(projectIndex,1);

            setNewProject(0)
            setInvoice(data.invoice)
            setInputValue({...inputValue, hours: data.invoice.total_hours, billed: data.invoice.total_billed})
        }
    }
    
    const removeProject = async (e) => {
        let projectID = e.currentTarget.id
        projectID = projectID.replace(`remove_`, "")

        const project = invoice.projects.find(project => (project.project_id === projectID));

        //call API to delete
        const data = await invoiceAPICall(invoice_rem_project, {'invoiceID': invoice.invoice_id ? invoice.invoice_id : 0, 'projectID': projectID, 'requester': userID, 'company': companyID })

        //if successful, delete from project object
        if(!data.success || data.error) {
            alert(data.error)
            return
        } 

        if(data.invoice) {
            setInvoice(data.invoice)
            setInputValue({...inputValue, hours: data.invoice.total_hours, billed: data.invoice.total_billed})
        }
        if(project) {
            setAvailableProjects([...availableProjects,project])
        }
    }

    const invoiceStatus = () => {
        if(newInvoice) return "New"
        switch(parseInt(invoice.status)) {
            case 1: return "Waiting Payment";
            case 2: return "Paid";
            default:
        }
        return "Pending";
    }

    const handleChange = (e) => {
        let { name, value } = e.target;

        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
        setErrors((prev) => ({
            ...prev,
            [field]: error,
        }));
    };

    if(invoiceID === "add" && !isSysadmin)
        return <InvalidPermissions />

    return ( 
        <>
        <Form title={!newInvoice ? "Edit Invoice" : "Add Invoice"}  description="" onSubmit={saveInvoice} headerAction={headerAction} >
            { !loading ?
            <>
            <Input
                type="text"
                value={invoiceStatus()}
                placeholder="Status"
                label="Status"
                readOnly={true}
                colSize="2"
            />
            <Select
                value={company}
                label="Company"
                name="company"
                formField="companies"
                useDefault={true}
                onChange={handleChange}
                onError={handleError}
                validate="numeric|required"
                colSize="6"
                readOnly={!canEdit}
            />

            <Input
                type="date"
                value={date}
                placeholder="Invoice Date"
                label="Invoice Date"
                name="date"
                onChange={handleChange}
                onError={handleError}
                validate="date|required"
                colSize="3"
                readOnly={!canEdit}
            />
            <Input
                type="number"
                value={hours}
                placeholder="Total Hours"
                label="Total Hours"
                name="hours"
                readOnly={true}
                colSize="2"
            />
            <Input
                type="number"
                value={billed}
                placeholder="Total Billed"
                label="Total Billed"
                name="billed"
                readOnly={true}
                colSize="2"
            />
            {canEdit && 
            <FormFooter 
                formButtons={newInvoice ? [{title: "Save Invoice", class: "primary"}] : [{title: "Save Invoice", class: "primary"}, {title: "Delete Invoice", class: "danger", onClick: confirmDeleteInvoice}]} 
                center={true} 
                formMessage={formMessage} 
                hasErrors={hasErrors} /> }
            </>
            : <p>Loading form data...</p> }
        </Form>
        {!newInvoice > 0 ? 
        <Card>
            <CardHeader title="Attached Projects" description="Projects included in invoice" />
            <Card.Body>
                <Table>
                <thead>
                    <tr>
                        <th>Project</th>
                        <th>Hours</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {invoice.projects && invoice.projects.map((project,p) => (
                        <tr key={`prow_${p}`}>
                            <td><a href={`/projects/${project.project_id}`} target="_blank" rel="noreferrer">{project.project_name}</a></td>
                            <td>{project.hours}</td>
                            <td>{isSysadmin && canEdit ? <span className="btn btn-danger btn-sm" id={`remove_${project.project_id}`} onClick={removeProject}>X</span> : ' '}</td>
                        </tr>
                    ))}
                </tbody>
                </Table>
            </Card.Body>
        </Card> : null }

        {!newInvoice > 0 && canEdit &&
        <Card>
            <CardHeader title="Add Project" description="" />
            <Card.Body>
                <Row>
                    <Col md={6}>
                        <select id="newProject" name="newProject" className="form-control" value={newProject} onChange={(e) => setNewProject(e.target.value)}>
                            <option value="0">-- Add a Project --</option>
                            {availableProjects.map((project, p) => (
                                <option value={project.project_id} key={`pOpt_${p}`}>{project.project_name}</option>
                            ))}
                        </select>
                    </Col>
                    <Col md={2}>
                        <Button title="Add Project" className="primary" onClick={addProject} />
                    </Col>
                </Row>
            </Card.Body>
        </Card> }
        <ConfirmDialog
            show={showConfirmDelete}
            title="Delete Invoice" message="Are you sure you wish to delete this Invoice?"
            onClose={() => { setShowConfirmDelete(false) }}
            onConfirm={deleteInvoice}
        />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectInvoice: (invoiceID) => { 
            dispatch(selectInvoice(invoiceID))
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(pageWithSideMenu(pageContent(withRouter(InvoiceForm)), "Invoice Form"));
