import React from 'react';

import { useState } from 'react'
import { CSVLink } from "react-csv";
import { Row, Col, FormGroup } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../containers/Form/Button';
import {  FaArrowRight, FaFileDownload } from 'react-icons/fa';
import moment from 'moment';

export default function MediaBuyOptions(props) {
    const [dateRange, setDateRange] = useState([new Date(props.startDate), new Date(props.endDate)]);
    const [startDate, endDate] = dateRange;

    return (
        <form className="form-horizontal" id="dashboardForm" method="GET" action={props.formAction}>
            <input type="hidden" id="startDate" name="startDate" value={moment(startDate).format('MM/DD/YYYY')} />
            <input type="hidden" id="endDate" name="endDate" value={moment(endDate).format('MM/DD/YYYY')} />
            <FormGroup>
                <Row>
                    <Col md={5}>&nbsp;</Col>
                    <Col md={5} className="text-right">
                        <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        className="form-control date-range-picker" 
                        />
                    </Col>
                    <Col md={2} id="form-btn">
                        <Button className="primary" Icon={FaArrowRight} />&nbsp;
                        {props.csvData && props.csvData.length > 0 ?
                        <CSVLink 
                            data={props.csvData}
                            filename={props.csvFileName}
                            className="btn btn-secondary"
                            target="_blank"><FaFileDownload size={14} /> </CSVLink>
                            : <></>}
                    </Col>
                </Row>
            </FormGroup>
        </form>
    )
}