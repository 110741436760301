import React from 'react';

import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import DataTable from '../../containers/DataTable'

import xmlAPICall from '../../app/apiCalls/xmlAPICall'
import { selectXML } from '../../app/store/actions/xmlActions'
import { xml_list } from '../../app/apiEndpoints'

const XMLTable = (props) => {
    const [data, setData] = useState([])

    const tableID = "tool"
    const tableFields = ["ID", "Name", "# Images", "Last Modified", ""]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentToolID)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    const onClick = (e) => {
        if(!props.showDetailsOnClick) return;
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        props.selectTool(rowID)
    }

    useMemo(() => {
        const getData = async () => {
            const data = await xmlAPICall(xml_list, {'requester': userID, 'company': companyID, 'status': (isSysadmin ? 0 : 1) })
            setData(data.xml)
        }
        getData()
    }, [userID, companyID, isSysadmin])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.xml_id, 'values': [
                row.xml_id,
                (props.showDetailsOnClick ? row.xml_name : `<a href="/xml/${row.xml_id}">${row.xml_name}</a>`),
                row.image_count,
                row.modified_date,
                `<a href="https://api.toplineadsportal.com/xml/feeds/?${row.xml_id}&redir=" target="_blank" class="btn btn-primary btn-sm">View</a> ${isSysadmin ? `<a href="/xml/feeds/${row.xml_id}/" class="btn btn-primary btn-sm">Edit</a>` : ''}`
            ]});
        });
        return rows
    }


    return (
        <>
        <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} onClick={onClick} currentRowID={rowID} />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectXML: (id) => { 
            dispatch(selectXML(id))
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(XMLTable)