import React from 'react';

import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import dashboardAPICall from '../../app/apiCalls/dashboardAPICall'
import { dashboard_details } from "../../app/apiEndpoints"
import DataTable from "../../containers/DataTable";
import CardHeader from '../../containers/CardHeader'
import { Breadcrumb, Card } from 'react-bootstrap'
import { tableTypes } from "../../app/settings";
import DashboardOptions from "./DashboardOptions";
import InvalidPermissions from '../../containers/InvalidPermissions'

import Moment from 'moment';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const DashboardView = (props) => {
    let query = useQuery();

    const [dashboard, setDashboard] = useState({})
    const [dashData, setDashData] = useState([])
    const [totals, setTotals] = useState({})
    let { dashURI } = useParams();
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const startDate = query.get("startDate")
    const endDate = query.get("endDate") 
    const [dataLoaded, setDataLoaded] = useState(false)
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
        const getDetails = async () => {
            if(dashURI.length > 0) {
                const data = await dashboardAPICall(dashboard_details, 
                    {'dashURI': dashURI, 
                        'requester': userID, 
                        'company': companyID, 
                        'startDate': startDate || (Moment().format('MM/DD/YYYY')), 
                        'endDate': endDate || (Moment().format('MM/DD/YYYY'))
                    })
                if(data.dashboard) {
                    setDashboard(data.dashboard) 
                    if(data.data) {
                        setDashData(data.data)
                    }
                    if(data.totals) {
                        setTotals(data.totals)
                    }
                }
                else if(data.error) {
                    setHasError(true)
                }
                setDataLoaded(true)
            }
        }
        if(!dataLoaded)
            getDetails()
    }, [dashURI, userID, companyID, startDate, endDate, dataLoaded])

    const tableFields = () => {
        if(dashData.length === 0) return []
        const row = dashData[0]
        const fields = Object.keys(row).map((field) => field)
        return fields
    }

    const tableData = () => {
        if(dashData.length === 0) return []
        const dataRows = []
        dashData.forEach((row, i) => {
            const values = []
            Object.keys(row).forEach((field) => values.push(row[field]))
            dataRows.push({'id': i, 'values': values});
        });
        return dataRows
    }

    const csvData = () => {
        if(dashData.length === 0) return []
        const dataRows = []
        dataRows.push(tableFields())
        dashData.forEach((row, i) => {
            const values = []
            Object.keys(row).forEach((field) => values.push(row[field]))
            dataRows.push(values);
        });
        return dataRows
    }

    const csvFileName = () => {
        if(dashboard.dashboard_name.length === 0) return "Dashboard"
        return `${dashboard.company_name.replaceAll(" ", "_")}_${dashboard.dashboard_name.replaceAll(" ", "_")}.csv`
    }
    
    if(hasError) 
        return <InvalidPermissions />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Dashboards</Breadcrumb.Item>
          <Breadcrumb.Item active>{dashboard.dashboard_name} &gt; {dashboard.company_name}</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={dashboard.dashboard_name} description={dashboard.company_name} />
            {dashData?.length > 0 ?
            <>
            <Card.Body>
            <DashboardOptions 
                dashID={dashboard.dashboard_id}
                startDate={startDate || (Moment().format('MM/DD/YYYY'))}
                endDate={endDate || (Moment().format('MM/DD/YYYY'))}
                formAction={`/dashboard/${dashURI}`}
                csvData={csvData()}
                csvFileName={csvFileName()} />
            </Card.Body>
            <DataTable 
                tableID={dashboard.dashboard_name} 
                fieldNames={tableFields()} 
                dataRows={tableData()} 
                tableSettings={tableTypes.None} 
                totals={totals}
                />
            </>
            : <></>}
            {!dataLoaded ? <Card.Body>Loading dashboard...</Card.Body> : <></>}
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(DashboardView), "Dashboard View"));
