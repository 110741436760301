import React from 'react';

import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import DataTable from '../../containers/DataTable'

import apisAPICall from '../../app/apiCalls/apisAPICall'
import { selectAPI } from '../../app/store/actions/apiActions'
import { apis_list } from '../../app/apiEndpoints'

const APITable = (props) => {
    const [APIs, setAPIs] = useState([])

    const tableID = "api"
    const tableFields = ["Name", "Client", "Last Modifed Date", "Status"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentAPIID)

    const onClick = (e) => {
        if(!props.showDetailsOnClick) return;
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        props.selectAPI(rowID)
    }

    useMemo(() => {
        const getAPIs = async () => {
            const data = await apisAPICall(apis_list, {'requester': userID, 'company': companyID })
            setAPIs(data.apis)
        }
        getAPIs()
    }, [userID, companyID])

    const tableData = () => {
        if(APIs.length === 0) {
            return [];
        } 
        let data = [];
        APIs.forEach(api => {
            data.push({'id': api.api_id, 'values': [
                (props.showDetailsOnClick ? api.api_name : `<a href="/apis/${api.api_id}">${api.api_name}</a>`),
                api.company_name,
                api.lastModifiedPretty,
                api.isactive === "1" ? "Active" : "Inactive"
            ]});
        });
        return data
    }

    return (
        <>
        <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} onClick={onClick} currentRowID={rowID} />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectAPI: (apiID) => { 
            dispatch(selectAPI(apiID))
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(APITable)