import React from 'react';

import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import BannerTable from './BannerTable'
import CardHeader from '../../containers/CardHeader'

import { FaPlus } from "react-icons/fa";
import { Breadcrumb, Card } from 'react-bootstrap'
import InvalidPermissions from '../../containers/InvalidPermissions'

const BannerSection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const title = "Banner Sites";
    const subtitle = "Select a Site to view its banners"
    const headerAction = isSysadmin ? {'path': "/banners/add", 'icon':FaPlus} : {}

    if(!isSysadmin) return <InvalidPermissions />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item active>Banners</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <BannerTable showDetailsOnClick={true} />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(BannerSection), "Banners"));
