import React from 'react';

import Button from './Button'

const FormFooter = ({formButtons, formButton, formMessage, hasErrors, center}) =>  {
    return (
        <>
        <div className={`card-footer ml-auto mr-auto ${center ? 'text-center' : ''}`}>
            <div className={`text-${hasErrors ? 'danger' : 'success'} p-3`} dangerouslySetInnerHTML={{ __html: (formMessage) }} />
            {formButton && 
            <Button title={formButton.title} className={formButton.class} onClick={formButton.onClick} /> }
            {formButtons &&
                formButtons.map((button, b) => (
                    <Button title={button.title} className={button.class} onClick={button.onClick} key={`button_${b}`} />
                )) }
        </div>
        </>
    )

}

export default FormFooter;