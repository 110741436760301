import React from 'react';

import { Card } from 'react-bootstrap';

const ContentNotFound = ({contentType}) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>{contentType} Not Found</Card.Title>
                <Card.Text>The {contentType} you were trying to access could not be found</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default ContentNotFound;
